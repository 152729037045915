import React from 'react'
// import { Helmet } from 'react-helmet';
import { Helmet } from 'react-helmet-async';
import useLocale from '../../Hooks/useLocale';
const TravelAgencies = () => {
  const localeLocalization = useLocale()
  return localeLocalization == 'it' ? <ItalianCom /> : <EnglishCom />

}

export default TravelAgencies;

const EnglishCom = () => {
  return <Helmet prioritizeSeoTags>
    <meta charSet="UTF-8" />
    <meta name="robots" content="noindex, nofollow" />
    <link rel="alternate" href="https://alysei.com/en/register/travel-agencies" hreflang="en" />
    <link rel="alternate" href="https://alysei.com/it/register/travel-agencies" hreflang="it" />
    {/* <!-- Primary Meta Tags --> */}

    <title>Register as a Travel Agency USA | Alysei</title>
    <meta name="description" content="Sign up as a travel agency on Alysei to offer exclusive Italian culinary tours and food experiences to clients, connecting with the best food and beverage suppliers." />
    <meta name="keywords" content="register as travel agency USA, Italian culinary tours USA, food experiences travel agency USA, connect with food suppliers USA, exclusive Italian food tours, Alysei travel agency network, offer Italian food tours, best Italian food suppliers, travel agency food experiences USA" />
    <link rel="canonical" href="https://alysei.com/en/register/travel-agencies" />

    {/* <!-- Open Graph / Facebook --> */}
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Register as a Travel Agency USA | Alysei" />
    <meta property="og:description" content="Sign up as a travel agency on Alysei to offer exclusive Italian culinary tours and food experiences to clients, connecting with the best food and beverage suppliers." />
    <meta property="og:url" content="https://alysei.com/en/register/travel-agencies" />
    <meta property="og:site_name" content="Alysei" />
    <meta property="og:image" content="https://alysei.com/dist/images/app-mockup.png" />

    {/* <!-- Twitter --> */}
    <meta property="twitter:card" content="summary" />
    <meta property="twitter:url" content="https://alysei.com/en/register/travel-agencies" />
    <meta property="twitter:title" content="Register as a Travel Agency USA | Alysei" />
    <meta property="twitter:description" content="Sign up as a travel agency on Alysei to offer exclusive Italian culinary tours and food experiences to clients, connecting with the best food and beverage suppliers." />
    <meta property="twitter:image" content="https://alysei.com/dist/images/app-mockup.png" />

  </Helmet>
}

const ItalianCom = () => {
  return <Helmet prioritizeSeoTags>
    <meta name="robots" content="noindex, nofollow" />
    <link rel="alternate" href="https://alysei.com/en/register/travel-agencies" hreflang="en" />
    <link rel="alternate" href="https://alysei.com/it/register/travel-agencies" hreflang="it" />
    {/* <!-- Primary Meta Tags --> */}
    <title>Registrati come Agenzia di Viaggio | Alysei</title>
    <meta name="description" content="Connettiti con produttori italiani di cibo e bevande per creare itinerari unici. Registrati su Alysei e scopri nuove opportunità B2B." />
    <link rel="canonical" href="https://alysei.com/it/register/travel-agencies" />

    {/* <!-- Open Graph / Facebook --> */}
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Registrati come Agenzia di Viaggio | Alysei" />
    <meta property="og:description" content="Connettiti con produttori italiani di cibo e bevande per creare itinerari unici. Registrati su Alysei e scopri nuove opportunità B2B." />
    <meta property="og:url" content="https://alysei.com/it/register/travel-agencies" />
    <meta property="og:site_name" content="Alysei" />
    <meta property="og:image" content="https://alysei.com/dist/images/app-mockup.png" />

    {/* <!-- Twitter --> */}
    <meta property="twitter:card" content="summary" />
    <meta property="twitter:url" content="https://alysei.com/it/register/travel-agencies" />
    <meta property="twitter:title" content="Registrati come Agenzia di Viaggio | Alysei" />
    <meta property="twitter:description" content="Connettiti con produttori italiani di cibo e bevande per creare itinerari unici. Registrati su Alysei e scopri nuove opportunità B2B." />
    <meta property="twitter:image" content="https://alysei.com/dist/images/app-mockup.png" />

  </Helmet>
}