import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';  // For redirecting users to the homepage or any other page
import img1 from "../../../src/assets/images/notfound.png";  // The image you want to use for the not found page
import { urlWithLocalization } from '../../components/LocalizationUrl/UrlWithLocalization';
import useLocale from '../../components/Hooks/useLocale';
export default function NotFound() {
    const localeLocalization=useLocale()
    const history = useHistory();  // Hook to handle redirection

    // Function to handle redirection to the home page
    const handleGoHome = () => {
        history.push(urlWithLocalization(localeLocalization,'/'));  // Redirect to the homepage
    };

    return (
        <Fragment>
            <div className="notFound-container">
                <div className="notFound-img">
                    <img src={img1} alt="Page Not Found" />
                </div>
                <h1 className="notFound-title">404 - Page Not Found</h1>
                <h4 className="">{'Oops! The page you are looking for does not exist.'}</h4>
                <button className="back-to-home" onClick={handleGoHome}>Go to Home</button>
            </div>

            {/* Add some styles if required */}
            <style jsx>{`
                .notFound-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    height: 100vh;
                    text-align: center;
                    padding: 20px;
                }
                .notFound-img img {
                    width: 200px;
                    height: auto;
                    margin-bottom: 20px;
                }
                .notFound-title {
                    font-size: 2rem;
                    font-weight: bold;
                    color: #333;
                    margin-bottom: 10px;
                }
                .notFound-message {
                    font-size: 1.25rem;
                    color: #555;
                    margin-bottom: 20px;
                }
                .back-to-home {
                    padding: 10px 20px;
                    background-color: #007bff;
                    color: white;
                    border: none;
                    cursor: pointer;
                    font-size: 1rem;
                    border-radius: 5px;
                }
                .back-to-home:hover {
                    background-color: #0056b3;
                }
            `}</style>
        </Fragment>
    );
}