import React from 'react'
// import { Helmet } from 'react-helmet'
import { Helmet } from 'react-helmet-async'
import useLocale from '../../Hooks/useLocale'
const ImportersDistributors = () => {
  const localeLocalization = useLocale()
  return localeLocalization == 'it' ? <ItalianCom /> : <EnglishCom />

}

export default ImportersDistributors;

const EnglishCom = () => {
  return <Helmet prioritizeSeoTags>
    <meta charSet="UTF-8" />
    <meta name="robots" content="noindex, nofollow" />
    <link rel="alternate" href="https://alysei.com/en/register/importers-distributors" hreflang="en" />
    <link rel="alternate" href="https://alysei.com/it/register/importers-distributors" hreflang="it" />
    {/* <!-- Primary Meta Tags --> */}
    <title>Register as Importers & Distributors USA | Alysei</title>
    <meta name="description" content="Sign up as an importer or distributor on Alysei to connect with Italian food and beverage producers in the USA. Expand your business network and grow your brand within the culinary sector." />
    <meta name="keywords" content="register as Italian food importer USA, sign up Italian beverage distributor USA, Italian product distributors USA, wholesale Italian food USA, Italian wine distributors USA, expand business with Alysei, Italian food and beverage network USA, importers and distributors USA registration, culinary business USA, food distribution USA" />
    <link rel="canonical" href="https://alysei.com/en/register/importers-distributors" />

    {/* <!-- Open Graph / Facebook --> */}
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Register as Importers & Distributors USA | Alysei" />
    <meta property="og:description" content="Sign up as an importer or distributor on Alysei to connect with Italian food and beverage producers in the USA. Expand your business network and grow your brand within the culinary sector." />
    <meta property="og:url" content="https://alysei.com/en/register/importers-distributors" />
    <meta property="og:site_name" content="Alysei" />
    <meta property="og:image" content="https://alysei.com/dist/images/app-mockup.png" />

    {/* <!-- Twitter --> */}
    <meta property="twitter:card" content="summary" />
    <meta property="twitter:url" content="https://alysei.com/en/register/importers-distributors" />
    <meta property="twitter:title" content="Register as Importers & Distributors USA | Alysei" />
    <meta property="twitter:description" content="Sign up as an importer or distributor on Alysei to connect with Italian food and beverage producers in the USA. Expand your business network and grow your brand within the culinary sector." />
    <meta property="twitter:image" content="https://alysei.com/dist/images/app-mockup.png" />


  </Helmet>
}

const ItalianCom = () => {
  return <Helmet prioritizeSeoTags>
    <meta name="robots" content="noindex, nofollow" />
    <link rel="alternate" href="https://alysei.com/en/register/importers-distributors" hreflang="en" />
    <link rel="alternate" href="https://alysei.com/it/register/importers-distributors" hreflang="it" />
    {/* <!-- Primary Meta Tags --> */}
    <title>Registrati come Importatore & Distributore | Alysei</title>
    <meta name="description" content="Registrati su Alysei come importatore o distributore di prodotti italiani e connettiti con produttori di cibo e bevande. Scopri opportunità esclusive per il tuo business!" />
    <link rel="canonical" href="https://alysei.com/it/register/importers-distributors" />

    {/* <!-- Open Graph / Facebook --> */}
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Registrati come Importatore & Distributore | Alysei" />
    <meta property="og:description" content="Registrati su Alysei come importatore o distributore di prodotti italiani e connettiti con produttori di cibo e bevande. Scopri opportunità esclusive per il tuo business!" />
    <meta property="og:url" content="https://alysei.com/it/register/importers-distributors" />
    <meta property="og:site_name" content="Alysei" />
    <meta property="og:image" content="https://alysei.com/dist/images/app-mockup.png" />

    {/* <!-- Twitter --> */}
    <meta property="twitter:card" content="summary" />
    <meta property="twitter:url" content="https://alysei.com/it/register/importers-distributors" />
    <meta property="twitter:title" content="Registrati come Importatore & Distributore | Alysei" />
    <meta property="twitter:description" content="Registrati su Alysei come importatore o distributore di prodotti italiani e connettiti con produttori di cibo e bevande. Scopri opportunità esclusive per il tuo business!" />
    <meta property="twitter:image" content="https://alysei.com/dist/images/app-mockup.png" />

  </Helmet>
}