import React from "react";
// import { Helmet } from "react-helmet";
import { Helmet } from "react-helmet-async";
import useLocale from "../../Hooks/useLocale";
const VoiceExperts = () => {
    const localeLocalization = useLocale()
    return localeLocalization == 'it' ? <ItalianCom /> : <EnglishCom />
}
export default VoiceExperts;

const EnglishCom = () => {
    return <Helmet prioritizeSeoTags>
        <meta charSet="UTF-8" />
        <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
        <link rel="alternate" href="https://alysei.com/en/services/voice-of-experts" hreflang="en" />
        <link rel="alternate" href="https://alysei.com/it/services/voice-of-experts" hreflang="it" />
        {/* <!-- Primary Meta Tags --> */}
        <link rel="alternate" href="https://alysei.com/en" hreflang="en" />
        <link rel="alternate" href="https://alysei.com/it" hreflang="it" />
        <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
        <title>Connect with Italian Food & Beverage Professionals | Alysei</title>
        <meta name="description" content="Join Alysei's Voice of Experts service to connect with top Italian food and beverage professionals. Gain valuable insights, expert advice, and exclusive knowledge on Italian gastronomy and culinary trends." />
        <meta name="keywords" content="Italian Food and Beverage Experts, Culinary Professionals Italy, Italian Gastronomy Experts, Expert Advice Italian Cuisine, Food and Beverage Insight Italy, Italian Food Industry Experts, Italian Food Expertise USA" />
        <link rel="canonical" href="https://alysei.com/en/services/voice-of-experts" />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Connect with Italian Food & Beverage Professionals | Alysei" />
        <meta property="og:description" content="Join Alysei's Voice of Experts service to connect with top Italian food and beverage professionals. Gain valuable insights, expert advice, and exclusive knowledge on Italian gastronomy and culinary trends." />
        <meta property="og:url" content="https://alysei.com/en/services/voice-of-experts" />
        <meta property="og:site_name" content="Alysei" />
        <meta property="og:image" content="https://alysei.com/dist/images/expert-contentimg.png" />

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:url" content="https://alysei.com/en/services/voice-of-experts" />
        <meta property="twitter:title" content="Connect with Italian Food & Beverage Professionals | Alysei" />
        <meta property="twitter:description" content="Join Alysei's Voice of Experts service to connect with top Italian food and beverage professionals. Gain valuable insights, expert advice, and exclusive knowledge on Italian gastronomy and culinary trends." />
        <meta property="twitter:image" content="https://alysei.com/dist/images/expert-contentimg.png" />


    </Helmet>
}
const ItalianCom = () => {
    return <Helmet prioritizeSeoTags>
        <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
        <link rel="alternate" href="https://alysei.com/en/services/voice-of-experts" hreflang="en" />
        <link rel="alternate" href="https://alysei.com/it/services/voice-of-experts" hreflang="it" />

        {/* <!-- Primary Meta Tags --> */}
        <title>Esperti di Cibo e Bevande Italiane | Alysei</title>
        <meta name="description" content="Affidati agli esperti di gastronomia italiana su Alysei. Scopri insight professionali, consulenze e soluzioni per il settore food & beverage in Italia." />
        <link rel="canonical" href="https://alysei.com/it/services/voice-of-experts" />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Esperti di Cibo e Bevande Italiane | Alysei" />
        <meta property="og:description" content="Affidati agli esperti di gastronomia italiana su Alysei. Scopri insight professionali, consulenze e soluzioni per il settore food & beverage in Italia." />
        <meta property="og:url" content="https://alysei.com/it/services/voice-of-experts" />
        <meta property="og:site_name" content="Alysei" />
        <meta property="og:image" content="https://alysei.com/dist/images/expert-contentimg.png" />

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:url" content="https://alysei.com/it/services/voice-of-experts" />
        <meta property="twitter:title" content="Esperti di Cibo e Bevande Italiane | Alysei" />
        <meta property="twitter:description" content="Affidati agli esperti di gastronomia italiana su Alysei. Scopri insight professionali, consulenze e soluzioni per il settore food & beverage in Italia." />
        <meta property="twitter:image" content="https://alysei.com/dist/images/expert-contentimg.png" />


    </Helmet>
}