import React from 'react';
// import { Helmet } from 'react-helmet';
import { Helmet } from 'react-helmet-async';

const SeoBookDemo = () => {
    return (
        <EnglishCom />
    );
};

export default SeoBookDemo;

const EnglishCom = () => {
    return <Helmet prioritizeSeoTags>

        {/* Primary Meta Tags */}
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="description" content="Book a personalized product demo with our team. See how our solution can benefit your business." />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Schedule Your Personalized Product Demo" />
        <meta property="og:description" content="Book a personalized product demo and learn how our solution can help your business grow." />
        <meta property="og:url" content="https://calendly.com/alysei2024" />


        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content="Book a personalized demo to see how our product can help your business." />
        <meta name="twitter:title" content="Schedule Your Product Demo" />
        <meta property="og:url" content="https://calendly.com/alysei2024" />
        <meta property="og:site_name" content="Alysei" />
        <meta property="og:image" content="https://alysei.com/dist/images/login-to-alysei.png" />


    </Helmet>
}
