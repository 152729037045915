import React, { useEffect } from "react";
import { renderRoutes } from "react-router-config";
import { useLocation } from "react-router-dom";
import Header from "../LandingPage/component/Header";
import Footer from "../LandingPage/component/Footer";
import withLocalization from "../../components/HOC/withLocalization";
function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function LandingLayout({ children, route }) {
  const { pathname } = useLocation();
  // @@ html tag update lang
  React.useEffect(() => {
    let lang = localStorage.getItem('lang') || 'en'
    document.documentElement.lang = lang;
  }, []);



  // Define the paths where the header and footer should be visible
  const pathsWithHeaderFooter = [
    '/',
    `/en/`,
    '/it/',
    '/en',
    '/it',


    '/about',
    `/en/about/`,
    '/it/about/',
    `/en/about`,
    '/it/about',

    // '/:lang/about',
    // '/:lang/about/',

    '/faq',
    '/en/faq/',
    '/it/faq/',
    '/en/faq',
    '/it/faq',
    // '/:lang/faq',
    // '/:lang/faq/',

    '/signIn',
    '/en/signIn/',
    '/it/signIn/',
    '/en/signIn',
    '/it/signIn',
    // '/:lang/signIn',


    '/signup',
    '/en/signup/',
    '/it/signup/',
    '/en/signup',
    '/it/signup',
    // '/:lang/signup',

    '/register/:role',
    '/:lang/register/:role',

    '/login',
    '/en/login/',
    '/it/login/',
    '/en/login',
    '/it/login',
    // '/:lang/login',
    '/unsubscribe'
  ];

  console.log('pathsWithHeaderFooter', pathname, pathsWithHeaderFooter)
  // Check if the current pathname matches any of the allowed paths
  const showHeaderFooter = pathsWithHeaderFooter.some((path) => {
    // pathname.match(new RegExp(`^${path.replace(/:\w+/g, '\\w+')}$`)) 
    const regexPath = new RegExp('^' + path.replace(/:\w+/g, '[\\w-]+') + '$');
    return regexPath.test(pathname);
  }
  );

  return (
    <>
      <ScrollToTop />
      {showHeaderFooter && <Header />}
      {children}
      {renderRoutes(route.routes)}
      {showHeaderFooter && <Footer />}
    </>
  );
}

export default withLocalization(LandingLayout);
