import React from 'react'
// import { Helmet } from 'react-helmet'
import { Helmet } from 'react-helmet-async';
import useLocale from '../Hooks/useLocale';
const SeoContact = () => {
  const localeLocalization = useLocale()
  return localeLocalization == 'it' ? <ItalianCom /> : <EnglishCom />

}

export default SeoContact;

const EnglishCom = () => {
  return <Helmet prioritizeSeoTags>
    <meta charSet="UTF-8" />
    <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
    <link rel="alternate" href="https://alysei.com/en/contact" hreflang="en" />
    <link rel="alternate" href="https://alysei.com/it/contact" hreflang="it" />


    {/* <!-- Primary Meta Tags --> */}
    <title>Contact Alysei | Connect with Us for Italian F&B Solutions</title>
    <meta name="description" content="Reach out to Alysei for all your Italian food and beverage needs. Contact us to explore our B2B & B2C services, and learn how we can help expand your brand and business in the USA." />
    <meta name="keywords" content="Alysei Contact Support, Alysei Customer Suppot, Alysei Contact No" />
    <link rel="canonical" href="https://alysei.com/en/contact" />

    {/* <!-- Open Graph / Facebook --> */}
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Contact Alysei | Connect with Us for Italian F&B Solutions" />
    <meta property="og:description" content="Reach out to Alysei for all your Italian food and beverage needs. Contact us to explore our B2B & B2C services, and learn how we can help expand your brand and business in the USA." />
    <meta property="og:url" content="https://alysei.com/en/contact" />
    <meta property="og:site_name" content="Alysei" />
    <meta property="og:image" content="https://alysei.com/dist/images/logo-blue.svg" />

    {/* <!-- Twitter --> */}
    <meta property="twitter:card" content="summary" />
    <meta property="twitter:url" content="https://alysei.com/en/contact" />
    <meta property="twitter:title" content="Contact Alysei | Connect with Us for Italian F&B Solutions" />
    <meta property="twitter:description" content="Reach out to Alysei for all your Italian food and beverage needs. Contact us to explore our B2B & B2C services, and learn how we can help expand your brand and business in the USA." />
    <meta property="twitter:image" content="https://alysei.com/dist/images/logo-blue.svg" />

  </Helmet>
}
const ItalianCom = () => {
  return <Helmet prioritizeSeoTags>
    <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
    <link rel="alternate" href="https://alysei.com/en/contact" hreflang="en" />
    <link rel="alternate" href="https://alysei.com/it/contact" hreflang="it" />


    {/* <!-- Primary Meta Tags --> */}
    <title>Contattaci | Alysei - Supporto e Opportunità di Business</title>
    <meta name="description" content="Hai domande o bisogno di assistenza? Contatta il team di Alysei per supporto su servizi, collaborazioni e opportunità di business nel settore food & beverage." />
    <link rel="canonical" href="https://alysei.com/it/contact" />

    {/* <!-- Open Graph / Facebook --> */}
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Contattaci | Alysei - Supporto e Opportunità di Business" />
    <meta property="og:description" content="Hai domande o bisogno di assistenza? Contatta il team di Alysei per supporto su servizi, collaborazioni e opportunità di business nel settore food & beverage." />
    <meta property="og:url" content="https://alysei.com/it/contact" />
    <meta property="og:site_name" content="Alysei" />
    <meta property="og:image" content="https://alysei.com/dist/images/logo-blue.svg" />

    {/* <!-- Twitter --> */}
    <meta property="twitter:card" content="summary" />
    <meta property="twitter:url" content="https://alysei.com/it/contact" />
    <meta property="twitter:title" content="Contattaci | Alysei - Supporto e Opportunità di Business" />
    <meta property="twitter:description" content="Hai domande o bisogno di assistenza? Contatta il team di Alysei per supporto su servizi, collaborazioni e opportunità di business nel settore food & beverage." />
    <meta property="twitter:image" content="https://alysei.com/dist/images/logo-blue.svg" />

  </Helmet>
}