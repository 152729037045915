
// @ function return url with locale en | it

export function urlWithLocalization(locale, url) {
    console.log('locale',locale)
    if (url && url !='/') {
        let rUrl =url=='/' ? url: url.startsWith('/') ? url : `/${url}`
        return `/${locale}${rUrl}`;
    } else {
        return `/${locale}`
    }
}
