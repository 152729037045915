import React from "react";
// import { Helmet } from "react-helmet"
import { Helmet } from "react-helmet-async";
import useLocale from "../../Hooks/useLocale";
const Buyers = () => {
    const localeLocalization = useLocale()
    return localeLocalization == 'it' ? <ItalianCom /> : <EnglishCom />
}

export default Buyers;

const EnglishCom = () => {
    return <Helmet prioritizeSeoTags>
        <meta charSet="UTF-8" />
        <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
        <link rel="alternate" href="https://alysei.com/en/services/buyers" hreflang="en" />
        <link rel="alternate" href="https://alysei.com/it/services/buyers" hreflang="it" />

        {/* <!-- Primary Meta Tags --> */}
        <title>Wholesale Italian Food & Beverages for Buyers | Alysei</title>
        <meta name="description" content="Find the best deals on Italian food and beverages with Alysei. Connect with trusted suppliers and buy wholesale Italian products, including food, wine, and beverages for your business in the USA." />
        <meta name="keywords" content="Italian food buyers USA, Wholesale Italian products buyers USA, Italian food and beverage buyers USA, Italian food sourcing for buyers USA, Bulk Italian food buyers USA, Italian product buyers USA, Authentic Italian product sourcing USA, Wholesale Italian food for buyers USA, Italian food distributors for buyers USA, Buy Italian food products online USA" />
        <link rel="canonical" href="https://alysei.com/en/services/buyers" />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Wholesale Italian Food & Beverages for Buyers | Alysei" />
        <meta property="og:description" content="Find the best deals on Italian food and beverages with Alysei. Connect with trusted suppliers and buy wholesale Italian products, including food, wine, and beverages for your business in the USA." />
        <meta property="og:url" content="https://alysei.com/en/services/buyers" />
        <meta property="og:site_name" content="Alysei" />
        <meta property="og:image" content="https://alysei.com/dist/images/buyer-contentimg.png" />

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:url" content="https://alysei.com/en/services/buyers" />
        <meta property="twitter:title" content="Wholesale Italian Food & Beverages for Buyers | Alysei" />
        <meta property="twitter:description" content="Find the best deals on Italian food and beverages with Alysei. Connect with trusted suppliers and buy wholesale Italian products, including food, wine, and beverages for your business in the USA." />
        <meta property="twitter:image" content="https://alysei.com/dist/images/buyer-contentimg.png" />

    </Helmet>
}
const ItalianCom = () => {
    return <Helmet prioritizeSeoTags>
        <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
        <link rel="alternate" href="https://alysei.com/en/services/buyers" hreflang="en" />
        <link rel="alternate" href="https://alysei.com/it/services/buyers" hreflang="it" />

        {/* <!-- Primary Meta Tags --> */}
        <title>Acquirenti di Prodotti Alimentari Italiani | Alysei</title>
        <meta name="description" content="Scopri Alysei, la piattaforma ideale per acquirenti di prodotti alimentari italiani all'ingrosso. Trova fornitori affidabili di cibo e bevande autentici, sourcing diretto e distribuzione su larga scala." />
        <link rel="canonical" href="https://alysei.com/it/services/buyers" />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Acquirenti di Prodotti Alimentari Italiani | Alysei" />
        <meta property="og:description" content="Scopri Alysei, la piattaforma ideale per acquirenti di prodotti alimentari italiani all'ingrosso. Trova fornitori affidabili di cibo e bevande autentici, sourcing diretto e distribuzione su larga scala." />
        <meta property="og:url" content="https://alysei.com/it/services/buyers" />
        <meta property="og:site_name" content="Alysei" />
        <meta property="og:image" content="https://alysei.com/dist/images/buyer-contentimg.png" />

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:url" content="https://alysei.com/it/services/buyers" />
        <meta property="twitter:title" content="Acquirenti di Prodotti Alimentari Italiani | Alysei" />
        <meta property="twitter:description" content="Scopri Alysei, la piattaforma ideale per acquirenti di prodotti alimentari italiani all'ingrosso. Trova fornitori affidabili di cibo e bevande autentici, sourcing diretto e distribuzione su larga scala." />
        <meta property="twitter:image" content="https://alysei.com/dist/images/buyer-contentimg.png" />

    </Helmet>
}