import React from 'react'
// import { Helmet } from 'react-helmet'
import { Helmet } from 'react-helmet-async'
import useLocale from '../Hooks/useLocale'
const SeoAbout = () => {
  const localeLocalization = useLocale()
  return localeLocalization == 'it' ? <ItalianCom /> : <EnglishCom />

}

export default SeoAbout

const EnglishCom = () => {
  return <Helmet prioritizeSeoTags>
    <meta charSet="UTF-8" />
    <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
    <link rel="alternate" href="https://alysei.com/en/about" hreflang="en" />
    <link rel="alternate" href="https://alysei.com/it/about" hreflang="it" />

    {/* <!-- Primary Meta Tags --> */}
    <title>About Alysei - Italian Food & Beverage Solutions</title>
    <meta name="description" content="Alysei, the premier platform connecting Italian food and beverage brands with global markets. Explore our services and expertise in supporting B2B & B2C businesses in the USA and beyond." />
    <meta name="keywords" content="Alysei About, Alysei About Us, Alysei" />
    <link rel="canonical" href="https://alysei.com/en/about" />

    {/* <!-- Open Graph / Facebook --> */}
    <meta property="og:type" content="website" />
    <meta property="og:title" content="About Alysei - Italian Food & Beverage Solutions" />
    <meta property="og:description" content="Alysei, the premier platform connecting Italian food and beverage brands with global markets. Explore our services and expertise in supporting B2B & B2C businesses in the USA and beyond." />
    <meta property="og:url" content="https://alysei.com/en/about" />
    <meta property="og:site_name" content="Alysei" />
    <meta property="og:image" content="https://alysei.com/dist/images/office-locations.png" />


    {/* <!-- Twitter --> */}
    <meta property="twitter:card" content="summary" />
    <meta property="twitter:url" content="https://alysei.com/en/about" />
    <meta property="twitter:title" content="About Alysei - Italian Food & Beverage Solutions" />
    <meta property="twitter:description" content="Alysei, the premier platform connecting Italian food and beverage brands with global markets. Explore our services and expertise in supporting B2B & B2C businesses in the USA and beyond." />
    <meta property="twitter:image" content="https://alysei.com/dist/images/office-locations.png" />

  </Helmet>
}

const ItalianCom = () => {
  return <Helmet prioritizeSeoTags>
    <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
    <link rel="alternate" href="https://alysei.com/en/about" hreflang="en" />
    <link rel="alternate" href="https://alysei.com/it/about" hreflang="it" />

    {/* <!-- Primary Meta Tags --> */}
    <title>Chi Siamo | Alysei - Soluzioni per il Settore Food & Beverage</title>
    <meta name="description" content="Scopri la missione di Alysei nel connettere produttori, distributori e appassionati di cibo e bevande italiane in un'unica piattaforma per il mercato globale." />
    <link rel="canonical" href="https://alysei.com/it/about" />

    {/* <!-- Open Graph / Facebook --> */}
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Chi Siamo | Alysei - Soluzioni per il Settore Food & Beverage" />
    <meta property="og:description" content="Scopri la missione di Alysei nel connettere produttori, distributori e appassionati di cibo e bevande italiane in un'unica piattaforma per il mercato globale." />
    <meta property="og:url" content="https://alysei.com/it/about" />
    <meta property="og:site_name" content="Alysei" />
    <meta property="og:image" content="https://alysei.com/dist/images/office-locations.png" />

    {/* <!-- Twitter --> */}
    <meta property="twitter:card" content="summary" />
    <meta property="twitter:url" content="https://alysei.com/it/about" />
    <meta property="twitter:title" content="Chi Siamo | Alysei - Soluzioni per il Settore Food & Beverage" />
    <meta property="twitter:description" content="Scopri la missione di Alysei nel connettere produttori, distributori e appassionati di cibo e bevande italiane in un'unica piattaforma per il mercato globale." />
    <meta property="twitter:image" content="https://alysei.com/dist/images/office-locations.png" />

  </Helmet>
}