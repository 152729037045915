import React from 'react'
// import { Helmet } from 'react-helmet'
import { Helmet } from 'react-helmet-async'
import useLocale from '../../Hooks/useLocale'
const ItalianRestaurant = () => {
  const localeLocalization = useLocale();

  return localeLocalization == 'it' ? <ItalianCom /> : <EnglishCom />

}

export default ItalianRestaurant

const EnglishCom = () => {
  return <Helmet prioritizeSeoTags>
    <meta charSet="UTF-8" />
    <meta name="robots" content="noindex, nofollow" />
    <link rel="alternate" href="https://alysei.com/en/register/italian-restaurants" hreflang="en" />
    <link rel="alternate" href="https://alysei.com/it/register/italian-restaurants" hreflang="it" />
    {/* <!-- Primary Meta Tags --> */}
    <title>Register as Italian Restaurant USA | Alysei</title>
    <meta name="description" content="Join Alysei as an Italian restaurant to connect with top Italian food suppliers, discover exclusive products, and enhance your menu with authentic ingredients." />
    <meta name="keywords" content="register Italian restaurant USA, Italian food suppliers USA, authentic Italian ingredients USA, connect with Italian food suppliers USA, Alysei Italian restaurant network, exclusive Italian products USA, enhance restaurant menu USA, Italian restaurant suppliers USA, restaurant ingredients USA, authentic Italian menu USA" />
    <link rel="canonical" href="https://alysei.com/en/register/italian-restaurants" />

    {/* <!-- Open Graph / Facebook --> */}
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Register as Italian Restaurant USA | Alysei" />
    <meta property="og:description" content="Join Alysei as an Italian restaurant to connect with top Italian food suppliers, discover exclusive products, and enhance your menu with authentic ingredients." />
    <meta property="og:url" content="https://alysei.com/en/register/italian-restaurants" />
    <meta property="og:site_name" content="Alysei" />
    <meta property="og:image" content="https://alysei.com/dist/images/app-mockup.png" />

    {/* <!-- Twitter --> */}
    <meta property="twitter:card" content="summary" />
    <meta property="twitter:url" content="https://alysei.com/en/register/italian-restaurants" />
    <meta property="twitter:title" content="Register as Italian Restaurant USA | Alysei" />
    <meta property="twitter:description" content="Join Alysei as an Italian restaurant to connect with top Italian food suppliers, discover exclusive products, and enhance your menu with authentic ingredients." />
    <meta property="twitter:image" content="https://alysei.com/dist/images/app-mockup.png" />


  </Helmet>
}
const ItalianCom = () => {
  return <Helmet prioritizeSeoTags>
    <meta name="robots" content="noindex, nofollow" />
    <link rel="alternate" href="https://alysei.com/en/register/italian-restaurants" hreflang="en" />
    <link rel="alternate" href="https://alysei.com/it/register/italian-restaurants" hreflang="it" />

    {/* <!-- Primary Meta Tags --> */}
    <title>Registrati come Ristorante Italiano | Alysei</title>
    <meta name="description" content="Unisciti ad Alysei per trovare fornitori di cibo e bevande autentici. Registrati ora e accedi a prodotti italiani all’ingrosso per il tuo ristorante." />
    <link rel="canonical" href="https://alysei.com/it/register/italian-restaurants" />

    {/* <!-- Open Graph / Facebook --> */}
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Registrati come Ristorante Italiano | Alysei" />
    <meta property="og:description" content="Unisciti ad Alysei per trovare fornitori di cibo e bevande autentici. Registrati ora e accedi a prodotti italiani all’ingrosso per il tuo ristorante." />
    <meta property="og:url" content="https://alysei.com/it/register/italian-restaurants" />
    <meta property="og:site_name" content="Alysei" />
    <meta property="og:image" content="https://alysei.com/dist/images/app-mockup.png" />

    {/* <!-- Twitter --> */}
    <meta property="twitter:card" content="summary" />
    <meta property="twitter:url" content="https://alysei.com/it/register/italian-restaurants" />
    <meta property="twitter:title" content="Registrati come Ristorante Italiano | Alysei" />
    <meta property="twitter:description" content="Unisciti ad Alysei per trovare fornitori di cibo e bevande autentici. Registrati ora e accedi a prodotti italiani all’ingrosso per il tuo ristorante." />
    <meta property="twitter:image" content="https://alysei.com/dist/images/app-mockup.png" />


  </Helmet>
}