import React from 'react'
// import { Helmet } from 'react-helmet'
import { Helmet } from 'react-helmet-async'
import useLocale from '../../Hooks/useLocale'
const Buyers = () => {
  const localeLocalization = useLocale()
  return localeLocalization == 'it' ? <ItalianCom /> : <EnglishCom />

}

export default Buyers;
const EnglishCom = () => {
  return<Helmet prioritizeSeoTags>
    <meta charSet="UTF-8" />
    <meta name="robots" content="noindex, nofollow" />
    <link rel="alternate" href="https://alysei.com/en/register/buyers" hreflang="en" />
    <link rel="alternate" href="https://alysei.com/it/register/buyers" hreflang="it" />

    {/* <!-- Primary Meta Tags --> */}
    <title>Register as a Buyer USA | Alysei</title>
    <meta name="description" content="Sign up as a buyer on Alysei to access a wide range of authentic Italian food and beverage products. Find the best suppliers and grow your business in the USA." />
    <meta name="keywords" content="register as buyer USA, Italian food buyers USA, Italian beverage buyers USA, food suppliers USA, beverage suppliers USA, authentic Italian products USA, Alysei food and beverage marketplace, Italian product buyers, grow your business USA, find Italian suppliers USA" />
    <link rel="canonical" href="https://alysei.com/en/register/buyers" />

    {/* <!-- Open Graph / Facebook --> */}
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Register as a Buyer USA | Alysei" />
    <meta property="og:description" content="Sign up as a buyer on Alysei to access a wide range of authentic Italian food and beverage products. Find the best suppliers and grow your business in the USA." />
    <meta property="og:url" content="https://alysei.com/en/register/buyers" />
    <meta property="og:site_name" content="Alysei" />
    <meta property="og:image" content="https://alysei.com/dist/images/app-mockup.png" />

    {/* <!-- Twitter --> */}
    <meta property="twitter:card" content="summary" />
    <meta property="twitter:url" content="https://alysei.com/en/register/buyers" />
    <meta property="twitter:title" content="Register as a Buyer USA | Alysei" />
    <meta property="twitter:description" content="Sign up as a buyer on Alysei to access a wide range of authentic Italian food and beverage products. Find the best suppliers and grow your business in the USA." />
    <meta property="twitter:image" content="https://alysei.com/dist/images/app-mockup.png" />



  </Helmet>
}

export const ItalianCom = () => {
  return <Helmet prioritizeSeoTags>
    <meta name="robots" content="noindex, nofollow" />
    <link rel="alternate" href="https://alysei.com/en/register/buyers" hreflang="en" />
    <link rel="alternate" href="https://alysei.com/it/register/buyers" hreflang="it" />
    {/* <!-- Primary Meta Tags --> */}
    <title>Registrati come Acquirente di Prodotti Italiani | Alysei</title>
    <meta name="description" content="Unisciti ad Alysei per scoprire fornitori di cibo e bevande italiane. Registrati ora e accedi a prodotti autentici all’ingrosso per il tuo business." />
    <link rel="canonical" href="https://alysei.com/it/register/buyers" />

    {/* <!-- Open Graph / Facebook --> */}
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Registrati come Acquirente di Prodotti Italiani | Alysei" />
    <meta property="og:description" content="Unisciti ad Alysei per scoprire fornitori di cibo e bevande italiane. Registrati ora e accedi a prodotti autentici all’ingrosso per il tuo business." />
    <meta property="og:url" content="https://alysei.com/it/register/buyers" />
    <meta property="og:site_name" content="Alysei" />
    <meta property="og:image" content="https://alysei.com/dist/images/app-mockup.png" />

    {/* <!-- Twitter --> */}
    <meta property="twitter:card" content="summary" />
    <meta property="twitter:url" content="https://alysei.com/it/register/buyers" />
    <meta property="twitter:title" content="Registrati come Acquirente di Prodotti Italiani | Alysei" />
    <meta property="twitter:description" content="Unisciti ad Alysei per scoprire fornitori di cibo e bevande italiane. Registrati ora e accedi a prodotti autentici all’ingrosso per il tuo business." />
    <meta property="twitter:image" content="https://alysei.com/dist/images/app-mockup.png" />

  </Helmet>
}