import { useMemo } from "react";

const useLocale = () => {
    return useMemo(() => {
        // const path = window.location.pathname;
        // const match = path.split("/")[1]; // Get the first part after the domain
        // console.log('matchmatch', match)
        let match=localStorage.getItem('lang') || localStorage.getItem('lang') || 'en';
        
        let localeVal = (match == 'it' || match == 'It' || match == 'IT' || match == 'iT') ? 'it' : 'en'
        return localeVal;
    }, []);
};

export default useLocale;
