import React from 'react'
// import { Helmet } from 'react-helmet'
import { Helmet } from 'react-helmet-async';
import useLocale from '../../Hooks/useLocale'
const TravelAgencies = () => {
  const localeLocalization = useLocale();
  return localeLocalization == 'it' ? <ItalianCom /> : <EnglishCom />

}

export default TravelAgencies

const EnglishCom = () => {
  return <Helmet prioritizeSeoTags>
    <meta charSet="UTF-8" />
    <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
    <link rel="alternate" href="https://alysei.com/en/services/travel-agencies" hreflang="en" />
    <link rel="alternate" href="https://alysei.com/it/services/travel-agencies" hreflang="it" />

    {/* <!-- Primary Meta Tags --> */}
    <title>Travel Agencies for Italian Food & Beverage Tourism USA | Alysei</title>
    <meta name="description" content="Connect with travel agencies specializing in Italian food and beverage tourism with Alysei. Explore unique culinary experiences, plan customized itineraries, and access B2B opportunities in the USA." />
    <meta name="keywords" content="Italian Travel Agencies USA, Italian Food Tourism Opportunities, Culinary Travel Itineraries Italy, Italian Gastronomy Travel USA, B2B Travel Solutions Italy, Italian Food & Beverage Tourism, Italian Travel and Culinary Experiences, Travel Agencies for Italian Cuisine Lovers, Italian Culinary Adventures USA, Italy Travel Agencies for Food Enthusiasts" />
    <link rel="canonical" href="https://alysei.com/en/services/travel-agencies" />

    {/* <!-- Open Graph / Facebook --> */}
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Travel Agencies for Italian Food & Beverage Tourism USA | Alysei" />
    <meta property="og:description" content="Connect with travel agencies specializing in Italian food and beverage tourism with Alysei. Explore unique culinary experiences, plan customized itineraries, and access B2B opportunities in the USA." />
    <meta property="og:url" content="https://alysei.com/en/services/travel-agencies" />
    <meta property="og:site_name" content="Alysei" />
    <meta property="og:image" content="https://alysei.com/dist/images/travel-contentimg.png" />

    {/* <!-- Twitter --> */}
    <meta property="twitter:card" content="summary" />
    <meta property="twitter:url" content="https://alysei.com/en/services/travel-agencies" />
    <meta property="twitter:title" content="Travel Agencies for Italian Food & Beverage Tourism USA | Alysei" />
    <meta property="twitter:description" content="Connect with travel agencies specializing in Italian food and beverage tourism with Alysei. Explore unique culinary experiences, plan customized itineraries, and access B2B opportunities in the USA." />
    <meta property="twitter:image" content="https://alysei.com/dist/images/travel-contentimg.png" />

  </Helmet>
}

const ItalianCom = () => {
  return <Helmet prioritizeSeoTags>
    <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
    <link rel="alternate" href="https://alysei.com/en/services/travel-agencies" hreflang="en" />
    <link rel="alternate" href="https://alysei.com/it/services/travel-agencies" hreflang="it" />

    {/* <!-- Primary Meta Tags --> */}
    <title>Agenzie di Viaggio & Esperienze Enogastronomiche Italiane | Alysei</title>
    <meta name="description" content="Scopri le opportunità B2B per agenzie di viaggio specializzate con Alysei. Connetti con produttori italiani di cibo e bevande e crea itinerari unici per esperienze indimenticabili." />
    <link rel="canonical" href="https://alysei.com/it/services/travel-agencies" />

    {/* <!-- Open Graph / Facebook --> */}
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Agenzie di Viaggio & Esperienze Enogastronomiche Italiane | Alysei" />
    <meta property="og:description" content="Scopri le opportunità B2B per agenzie di viaggio specializzate con Alysei. Connetti con produttori italiani di cibo e bevande e crea itinerari unici per esperienze indimenticabili." />
    <meta property="og:url" content="https://alysei.com/it/services/travel-agencies" />
    <meta property="og:site_name" content="Alysei" />
    <meta property="og:image" content="https://alysei.com/dist/images/travel-contentimg.png" />

    {/* <!-- Twitter --> */}
    <meta property="twitter:card" content="summary" />
    <meta property="twitter:url" content="https://alysei.com/it/services/travel-agencies" />

  </Helmet>
}