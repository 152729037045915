import React from 'react'
// import { Helmet } from 'react-helmet'
import { Helmet } from 'react-helmet-async'
import useLocale from '../../Hooks/useLocale'
const Voyagers = () => {
  const localeLocalization = useLocale()
  return localeLocalization == 'it' ? <ItalianCom /> : <EnglishCom />

}

export default Voyagers
const EnglishCom = () => {
  return <Helmet prioritizeSeoTags>
    <meta charSet="UTF-8" />
    <meta name="robots" content="noindex, nofollow" />
    <link rel="alternate" href="https://alysei.com/en/register/voyagers" hreflang="en" />
    <link rel="alternate" href="https://alysei.com/it/register/voyagers" hreflang="it" />
    {/* <!-- Primary Meta Tags --> */}

    <title>Register as a Voyager USA | Alysei</title>
    <meta name="description" content="Join Alysei as a Voyager to explore authentic Italian culinary experiences, share your journey with fellow food enthusiasts, and discover unique gastronomic adventures." />
    <meta name="keywords" content="register as voyager USA, Italian culinary experiences USA, gastronomic adventures USA, food enthusiast USA, explore Italian cuisine, Alysei food travel community, Italian food tours, culinary journey USA, share food experiences, discover Italian food USA" />
    <link rel="canonical" href="https://alysei.com/en/register/voyagers" />

    {/* <!-- Open Graph / Facebook --> */}
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Register as a Voyager USA | Alysei" />
    <meta property="og:description" content="Join Alysei as a Voyager to explore authentic Italian culinary experiences, share your journey with fellow food enthusiasts, and discover unique gastronomic adventures." />
    <meta property="og:url" content="https://alysei.com/en/register/voyagers" />
    <meta property="og:site_name" content="Alysei" />
    <meta property="og:image" content="https://alysei.com/dist/images/app-mockup.png" />

    {/* <!-- Twitter --> */}
    <meta property="twitter:card" content="summary" />
    <meta property="twitter:url" content="https://alysei.com/en/register/voyagers" />
    <meta property="twitter:title" content="Register as a Voyager USA | Alysei" />
    <meta property="twitter:description" content="Join Alysei as a Voyager to explore authentic Italian culinary experiences, share your journey with fellow food enthusiasts, and discover unique gastronomic adventures." />
    <meta property="twitter:image" content="https://alysei.com/dist/images/app-mockup.png" />

  </Helmet>
}
const ItalianCom = () => {
  return <Helmet prioritizeSeoTags>
    <meta name="robots" content="noindex, nofollow" />
    <link rel="alternate" href="https://alysei.com/en/register/voyagers" hreflang="en" />
    <link rel="alternate" href="https://alysei.com/it/register/voyagers" hreflang="it" />
    {/* <!-- Primary Meta Tags --> */}
    <title>Registrati come Viaggiatore | Alysei</title>
    <meta name="description" content="Scopri esperienze autentiche in Italia con Alysei. Registrati ora per accedere a viaggi enogastronomici e culturali unici." />
    <link rel="canonical" href="https://alysei.com/it/register/voyagers" />

    {/* <!-- Open Graph / Facebook --> */}
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Registrati come Viaggiatore | Alysei" />
    <meta property="og:description" content="Scopri esperienze autentiche in Italia con Alysei. Registrati ora per accedere a viaggi enogastronomici e culturali unici." />
    <meta property="og:url" content="https://alysei.com/it/register/voyagers" />
    <meta property="og:site_name" content="Alysei" />
    <meta property="og:image" content="https://alysei.com/dist/images/app-mockup.png" />

    {/* <!-- Twitter --> */}
    <meta property="twitter:card" content="summary" />
    <meta property="twitter:url" content="https://alysei.com/it/register/voyagers" />
    <meta property="twitter:title" content="Registrati come Viaggiatore | Alysei" />
    <meta property="twitter:description" content="Scopri esperienze autentiche in Italia con Alysei. Registrati ora per accedere a viaggi enogastronomici e culturali unici." />
    <meta property="twitter:image" content="https://alysei.com/dist/images/app-mockup.png" />
  </Helmet>
}