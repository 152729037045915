import React from 'react'
// import { Helmet } from 'react-helmet'
import { Helmet } from 'react-helmet-async'
import useLocale from '../Hooks/useLocale'
const SeoLogin = () => {
  const localeLocalization = useLocale();
  console.log('localeLocalization', localeLocalization)
  return localeLocalization == 'it' ? <ItalianCom /> : <EnglishCom />

}

export default SeoLogin

const EnglishCom = () => {
  return <Helmet prioritizeSeoTags>
    <meta charSet="UTF-8" />
    <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
    <link rel="alternate" href="https://alysei.com/en/login" hreflang="en" />
    <link rel="alternate" href="https://alysei.com/it/login" hreflang="it" />


    {/* <!-- Primary Meta Tags --> */}
    <title>Log In to Alysei - Connect with Italian Food & Beverage Experts USA</title>
    <meta name="description" content="Sign in to Alysei and access the leading platform for Italian food and beverage enthusiasts, businesses, and professionals in the USA. Connect and grow your network today!" />
    <meta name="keywords" content="Alysei Login, Sign In to Alysei, Alysei Member Login" />
    <link rel="canonical" href="https://alysei.com/en/login" />

    {/* <!-- Open Graph / Facebook --> */}
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Log In to Alysei - Connect with Italian Food & Beverage Experts USA" />
    <meta property="og:description" content="Sign in to Alysei and access the leading platform for Italian food and beverage enthusiasts, businesses, and professionals in the USA. Connect and grow your network today!" />
    <meta property="og:url" content="https://alysei.com/en/login" />
    <meta property="og:site_name" content="Alysei" />
    <meta property="og:image" content="https://alysei.com/dist/images/login-img.png" />

    {/* <!-- Twitter --> */}
    <meta property="twitter:card" content="summary" />
    <meta property="twitter:url" content="https://alysei.com/en/login" />
    <meta property="twitter:title" content="Log In to Alysei - Connect with Italian Food & Beverage Experts USA" />
    <meta property="twitter:description" content="Sign in to Alysei and access the leading platform for Italian food and beverage enthusiasts, businesses, and professionals in the USA. Connect and grow your network today!" />
    <meta property="twitter:image" content="https://alysei.com/dist/images/login-img.png" />

  </Helmet>
}
const ItalianCom = () => {
  return <Helmet prioritizeSeoTags>
    <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
    <link rel="alternate" href="https://alysei.com/en/login" hreflang="en" />
    <link rel="alternate" href="https://alysei.com/it/login" hreflang="it" />


    {/* <!-- Primary Meta Tags --> */}
    <title>Accedi a Alysei - Connettiti con Esperti del Food & Beverage Italiano</title>
    <meta name="description" content="Accedi al tuo account Alysei e scopri opportunità esclusive nel mondo del cibo e delle bevande italiane. Connettiti con professionisti e aziende del settore oggi stesso!" />
    <link rel="canonical" href="https://alysei.com/it/login" />

    {/* <!-- Open Graph / Facebook --> */}
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Accedi a Alysei - Connettiti con Esperti del Food & Beverage Italiano" />
    <meta property="og:description" content="Accedi al tuo account Alysei e scopri opportunità esclusive nel mondo del cibo e delle bevande italiane. Connettiti con professionisti e aziende del settore oggi stesso!" />
    <meta property="og:url" content="https://alysei.com/it/login" />
    <meta property="og:site_name" content="Alysei" />
    <meta property="og:image" content="https://alysei.com/dist/images/login-img.png" />

    {/* <!-- Twitter --> */}
    <meta property="twitter:card" content="summary" />
    <meta property="twitter:url" content="https://alysei.com/it/login" />
    <meta property="twitter:title" content="Accedi a Alysei - Connettiti con Esperti del Food & Beverage Italiano" />
    <meta property="twitter:description" content="Accedi al tuo account Alysei e scopri opportunità esclusive nel mondo del cibo e delle bevande italiane. Connettiti con professionisti e aziende del settore oggi stesso!" />
    <meta property="twitter:image" content="https://alysei.com/dist/images/login-img.png" />

  </Helmet>
}