import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../LandingPage/component/Header";
import loginImg from "../../assets/landing-page/login-img.png";
import language from "../../helpers/language";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword, resetPwdOtp, setResponseErrors, verifyUserEmaiId, voyagerRregister } from "../register/modules/register";
import { logIn } from "../login/modules/login";
import auth from "../../helpers/auth";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import FormSpinner from "../../components/Spinners/FormSpinner";
import Modal from "react-modal";
import OtpInput from "react-otp-input";
import SeoLogin from "../../components/Seo-helmet/SeoLogin";
import useLocale from "../../components/Hooks/useLocale";
import { urlWithLocalization } from "../../components/LocalizationUrl/UrlWithLocalization";
const SignIn = (props) => {
  const localeLocalization=useLocale()
  const [lang] = useState(language.getLang());
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const lowercase = (e) => {
    setEmail(e.target.value.toLowerCase());
  };
  const [loginErrorMsg, setLoginErrorMsg] = useState('');
  const dispatch = useDispatch()
  const [voyagersOtp, SetVoyagersOtp] = useState(false);
  const [voyagersEmail, SetVoyagersEmail] = useState();
  const [account_enabled, SetAccount_enabled] = useState();
  const history = useHistory();
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const { loginSpinner } = useSelector((state) => state.auth);
  const { resError } = useSelector((state) => state.register);
  const [forgotPwd, setForgotPwd] = useState(false);
  const [validEmail, setValidEmail] = useState('');
  const [verifyOtp, setVerifyOtp] = useState(false);
  const [isEmailAndOtpTrue, setIsEmailAndOtpTrue] = useState(false);
  const [otp, SetOtp] = useState();
  const [resetPwd, setResetPwd] = useState(false);
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState('');
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [otpVerifyError, setOtpVerifyError] = useState('');
  
 
  const handlechange = async (event) => {
    const name = event.target.id;
    const value = event.target.value;
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    } else if (name === "confirmPwd") {
      setConfirmPwd(value);
    }
  };

  const submitHandler = async (e) => {
    await e.preventDefault();
    const loginData = {
      email: email,
      password: password,
    };
    if (email === '' || password === '') {
      await setLoginErrorMsg(lang?.Please_Enter_email_and_pass);
    } else {
      await dispatch(logIn({ ...loginData, history: props.history })).then(
        async (res) => {
          if (res?.success === 200) {
            if (res.data.account_enabled === "incomplete") {
              await dispatch(verifyUserEmaiId(res.data?.email));
              SetVoyagersOtp(true);
              SetVoyagersEmail(res.data.email);
              SetAccount_enabled(res.data.account_enabled);
            } else {
              document.body.classList.remove(`register`);
              await auth.setTokens(
                res && res.token,
                JSON.stringify(res && res.data.roles)
              );
              await localStorage.setItem("alysei_review", res?.data?.alysei_review);
              if(res?.data?.role_id === 11){
                  history.push(`/buyer-dashboard`);
              }
              else{
                //console.log(res?.data?.alysei_review,'res?.data?.alysei_review..');
                const isGetingPublicPost = localStorage.getItem("post-url");
                isGetingPublicPost ? history.push(`${isGetingPublicPost}`) : history.push("/home");
              }
            }

            
          } else {
            setLoginErrorMsg(res?.error);
          }
        }
      );
    }
    setTimeout(() => {
      setLoginErrorMsg('')
    }, 3000);
  };
  const togglePassword = (type) => {
    type === 1 && setPasswordShown(!passwordShown);
    type === 2 && setConfirmPassword(!confirmPassword);

  };

  const forgotPassword = async (event) => {
    event.preventDefault();
    if (email !== undefined) {
      const response = await dispatch(verifyUserEmaiId(email));
      if (response.success === 200) {
        setVerifyOtp(true);
        setForgotPwd(false);
      } else {
        setValidEmail('Invalid email ID')
      }

    }
    setTimeout(() => {
      setValidEmail('')
    }, 3000)

  };

  const verifyPwdOtp = async (event) => {
    event.preventDefault();
    await setIsEmailAndOtpTrue(true);
    const model = {
      email: email,
      otp: otp,
    };
    await dispatch(resetPwdOtp(model)).then((res) => {
      if (res && res.success === 200) {
        setResetPwd(true);
        setIsEmailAndOtpTrue(false);
      } else {
        setIsEmailAndOtpTrue(false);
        setValidEmail(res.message);
        SetOtp();
      }
    });
    setTimeout(() => {
      setValidEmail('')
    }, 3000)
  };

  const resendOtp = async (email) => {
    const response = await dispatch(verifyUserEmaiId(email));
    if (response.success === 200) {
      dispatch(setResponseErrors(lang?.OTP_has_been_send_successfully));
      setValidEmail()
    } else {
      setValidEmail('somethings went wrong.')
    }
    setTimeout(() => {
      setValidEmail('');
      dispatch(setResponseErrors(''));
    }, 3000)
  }

  const changePwd = async (event) => {
    event.preventDefault();
    await setIsResetPassword(true);
    var digit = /(\d+)/;
    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    let result = format.test(password);
    let digitResult = digit.test(password);
    const model = {
      email: email,
      password: password,
      confirm_password: confirmPwd,
    };

    if (digitResult && result) {
      await dispatch(resetPassword(model)).then((res) => {
        if (res && res.success === 200) {
          setResetPasswordSuccess('Password has been reset successfully.');
          setTimeout(() => {
            SetOtp();
            setResetPwd(false);
            setForgotPwd(false);
            setVerifyOtp(false);
            setResetPasswordSuccess("");
          },3000);

        } else {
          SetOtp();
        }
      });
    } else {
      dispatch(setResponseErrors(lang?.ValidatePass))

    }
    await setIsResetPassword(false);
    setTimeout(() => {
      setValidEmail('')
      dispatch(setResponseErrors(''));
    }, 3000)
  };

  const varifyOtp = async (e) => {
    await e.preventDefault();
    const model = {
      account_enabled: account_enabled,
      email: voyagersEmail,
      otp: otp,
    };
    await dispatch(voyagerRregister(model)).then(async (res) => {
      if (res && res.success === 200) {
        // history.push(`/login/${role}`);
        //document.body.classList.remove(`register`);
        
        await auth.setTokens(
          res && res.token,
          JSON.stringify(res && res.data.roles)
        );
        //console.log(res?.data?.alysei_review,'res?.data?.alysei_review..');
        SetVoyagersOtp(false);
        await localStorage.setItem("alysei_review", res?.data?.alysei_review);
        if(res?.data?.roles?.role_id === 11){
              history.push(`/buyer-dashboard`);
        }
        else{
          if(res?.data?.alysei_review === 0){
          history.push(`/membership`);
          }
          else{
            history.push(`/home`);
          }
        }
      }
      else{
         await setOtpVerifyError(res.message);
      }
      setTimeout(() => {
        setOtpVerifyError('')
      }, 3000);
    });
  };
  return (
    <>
    {/* @@ SEO */}
    <SeoLogin />
    {/* @@ SEO */}
      <Header />
      <div className="auth-container flex md:px-8 px-4 pt-24 gap-12">
        <div className="w-7/12 relative hidden md:block">
          <div className="absolute bottom-0 p-6">
            <p className="font-bold text-xl mb-3 text-white">
            {lang.login_img_title}
            </p>
            <p className="text-white font-medium text-sm">
            {lang.login_img_subtitle}
            </p>
          </div>
          <img
            src={loginImg}
            className="w-full h-auto rounded-2xl"
            alt="Login"
          />
        </div>
        <div className="md:w-4/12 w-full input-container">
          <p className="font-bold text-3xl mb-4">{lang.Log_InAlysei}</p>
          <p className="text-dim font-medium text-sm mb-6">
            {lang.enter_details}
          </p>
          <form onSubmit={(e) => submitHandler(e)}>
            <div className="input-group mb-3">
              <label>Email</label>
              <input
                id="email"
                type="email"
                placeholder={lang?.Email}
                value={email}
                onChange={(e) => {
                  handlechange(e);
                  lowercase(e);
                }}
              />
            </div>
            <div className="input-group ">
           
              <label>Password</label>
                          <div className="relative">
                          <input  id="password"
                    type={passwordShown ? "text" : "password"}
                    placeholder="Password"
                    onChange={(e) => handlechange(e)}/>
                    
                     <span className=" absolute top-1/2 translate-middle right-2 text-royal">
                    <i className={!passwordShown ? "fa fa-eye-slash" : "fa fa-eye"} onClick={() => togglePassword(1)} aria-hidden="false"></i>
                  </span>
             </div>
             
            </div>
                      <div className="flex justify-between my-3">
                      <p className="font-semibold text-red-500">{loginErrorMsg}</p> 
                      <p    onClick={() => {
                setForgotPwd(!forgotPwd);
              }}
              className="text-royal text-right block font-semibold cursor-pointer "
            >
              {lang?.Forget_password}
            </p>    
            </div>
            
            <button type="submit" className="btn-royal w-full">
            {loginSpinner ? <FormSpinner /> : `${lang?.Login}`}
              </button>
          </form>
        

          <p className="text-center my-4 font-semibold">OR</p>
          <p className="font-semibold text-center">
             {lang.new_Account}
            <Link to={urlWithLocalization(localeLocalization,`/signup`)}>
              <span className="text-royal cursor-pointer ml-1">{ lang.create_Now}</span>
            </Link>
          </p>
        </div>
      </div>
         {/* Forgot Password Popup Start */}

         {forgotPwd && (
        <div>
          <Modal
            ariaHideApp={false}
            isOpen={forgotPwd}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.75)",
              },
              content: {
                position: "absolute",
                top: "0",
                left: "0%",
                right: "0%",
                bottom: "200px",
                border: "1px solid #ccc",
                background: "#fff",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                borderRadius: "4px",
                padding: "0",
              },
            }}
          >
            <div className="popup_header flex justify-between items-center">
              <h4 className="page__title text-center">Forgot Password</h4>
              <button
                className="close_btn--modal"
                onClick={() => setForgotPwd(!forgotPwd)}
              >
                close
              </button>
            </div>
            <div className="card card--block modal_box popUp">
              <p className="text-center mb-6">Enter your registered email to recover your password
              </p>
              <p style={{ color: 'red' }}>{validEmail}</p>
              <form className="" onSubmit={(event) => forgotPassword(event)} >
                <div className="form__action">
                  <div className="form__item">
                    <input
                       type="text"
                       placeholder="Email"
                       id="email"
                       name="email"
                       required
                       onChange={(e) => {
                         handlechange(e);
                         lowercase(e);
                       }}
                    />
                  </div>
                  
                  <button type="submit" className="btn w-full mt-6 mb-2" >
                   Continue
                  </button>
              
                </div>
              </form>
              <p style={{ color: "red" }}>{loginErrorMsg}</p>
            </div>
            <div></div>
          </Modal>
        </div>
      )}

      {/* Forgot Password Popup End */}
      {verifyOtp && (
        <div>
          <Modal
            ariaHideApp={false}
            isOpen={verifyOtp}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.75)",
              },
              content: {
                position: "absolute",
                top: "0",
                left: "0%",
                right: "0%",
                bottom: "200px",
                border: "1px solid #ccc",
                background: "#fff",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                borderRadius: "4px",
                padding: "0",
              },
            }}
          >
            <div className="popup_header flex justify-between items-center">
              <h4 className="page__title text-center">{lang?.Alysei}</h4>
              <button
                className="close_btn--modal"
                onClick={() => { setVerifyOtp(!verifyOtp); setValidEmail('') }}
              >
                {lang?.close}
              </button>
            </div>
            <div className="card card--block modal_box popUp">
              <p className="text-blue text-center mb-8">
                {lang?.We_have_sent_you_a_6_digit_verification_code_OTP}{" "}
                <strong>{email}</strong>
                <p className="text-blue text-center mb-8">{lang?.SPAM_FOLDER}.</p>
              </p>
              <p className="text-red-500">{validEmail}</p>
              <p className="text-green">{resError}</p>
              <form className="mb-2" onSubmit={(event) => verifyPwdOtp(event)}>
                <div className="form__action otp">
                  <OtpInput
                    value={otp}
                    onChange={(otp) => SetOtp(otp)}
                    numInputs={6}
                 />
                  <button type="submit" className="btn w-full mt-6 mb-6">
                    {isEmailAndOtpTrue ? <FormSpinner /> : "Reset Password"}
                  </button>
                  <p>
                    {lang?.Didnot_receive_the_OTP}{" "}
                    <Link to="#" onClick={() => resendOtp(email)} className="title-color font-semibold">{lang?.Resend_OTP}</Link>
                  </p>
                </div>
              </form>
            </div>
            <div></div>
          </Modal>
        </div>
      )}

{resetPwd && (
        <div>
          <Modal
            ariaHideApp={false}
            isOpen={resetPwd}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.75)",
              },
              content: {
                position: "absolute",
                top: "0",
                left: "0%",
                right: "0%",
                bottom: "200px",
                border: "1px solid #ccc",
                background: "#fff",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                borderRadius: "4px",
                padding: "0",
              },
            }}
          >
            <div className="popup_header flex justify-between items-center">
              <h4 className="page__title text-center">{lang?.Reset_Password}</h4>
              <button
                className="close_btn--modal"
                onClick={() => { setResetPwd(false); setVerifyOtp(false); SetOtp() }}
              >
                {lang?.close}
              </button>
            </div>
            <div className="card card--block modal_box popUp">
              <p style={{ color: "red" }}>{resError}</p>
              <form className="mb-2" onSubmit={(event) => changePwd(event)}>
                <div className="form__action">
                  <div className="form__item relative">
                    <span className="eye_show">
                      <i className={!passwordShown ? "fa fa-eye-slash" : "fa fa-eye"} onClick={() => togglePassword(1)} aria-hidden="false"></i>
                    </span>
                    <input
                      type={passwordShown ? "text" : "password"}
                      placeholder={lang?.New_Password}
                      name="password"
                      id="password"
                      value={password}
                      onChange={(e) => handlechange(e)}
                    />
                  </div>
                  <div className="form__item relative">
                    <span className="eye_show">
                      <i className={!confirmPassword ? "fa fa-eye-slash" : "fa fa-eye"} onClick={() => togglePassword(2)} aria-hidden="false"></i>
                    </span>
                    <input
                      type={confirmPassword ? "text" : "password"}
                      placeholder={lang?.Confirm_Password}
                      name="confirmPwd"
                      id="confirmPwd"
                      value={confirmPwd}
                      onChange={(e) => handlechange(e)}
                    />
                  </div>
                  <p className="text-center mb-6 mt-6">
                    {lang?.PassWordValidation}
                  </p>
                  <button type="submit" className="btn w-full" disabled={isResetPassword}>
                    {isResetPassword ? <FormSpinner/> : lang?.only_Submit}
                  </button>
                </div>
                <p style={{color:'green',padding:'10px 0px 0px 0px',textAlign:'center',fontSize:'16px'}}>{resetPasswordSuccess}</p>
              </form>
            </div>
            <div></div>
          </Modal>
        </div>
      )}

{
        <div>
          {voyagersOtp && (
            <div>
              <Modal
                ariaHideApp={false}
                isOpen={voyagersOtp}
                style={{
                  overlay: {
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(255, 255, 255, 0.75)",
                  },
                  content: {
                    position: "absolute",
                    top: "0",
                    left: "0%",
                    right: "0%",
                    bottom: "200px",
                    border: "1px solid #ccc",
                    background: "#fff",
                    overflow: "auto",
                    WebkitOverflowScrolling: "touch",
                    borderRadius: "4px",
                    padding: "0",
                  },
                }}
              >
                <div className="popup_header flex justify-between items-center">
                  <h4 className="page__title text-center">{lang?.Alysei}</h4>
                  <button
                    className="close_btn--modal"
                    onClick={() => SetVoyagersOtp(false)}
                  >
                    {lang?.close}
                  </button>
                </div>
                <div className="card card--block modal_box popUp">
                  <p className="text-blue text-center mb-8">
                    {lang?.We_have_sent_you_a_6_digit_verification_code_OTP}{" "}
                    <strong>{voyagersEmail}</strong>
                    <p className="text-blue text-center mb-8">{lang?.SPAM_FOLDER}.</p>
                  </p>
                  {/* <p className="text-red-500">{validEmail}</p> */}
                  <p className="text-green">{resError}</p>
                  <form className="mb-2" onSubmit={(event) => varifyOtp(event)}>
                    <label>{lang?.Verify_Otp}</label>
                    <div className="form__action otp">
                      <OtpInput
                        value={otp}
                        onChange={(otp) => SetOtp(otp)}
                        numInputs={6}
                      />
                      <p className="text-red-500">{otpVerifyError}</p>
                      <button type="submit" className="btn w-full mt-6 mb-6">
                        {lang?.Save}
                      </button>
                      <p>
                        {lang?.Didnot_receive_the_OTP}{" "}
                        <Link to="#" onClick={() => resendOtp(voyagersEmail)} className="title-color font-semibold">{lang?.Resend_OTP}</Link>
                      </p>
                    </div>
                  </form>
                </div>
                <div></div>
              </Modal>
            </div>
          )}</div>
      }
    </>
  );
};

export default SignIn;
