import React from 'react'
// import { Helmet } from 'react-helmet';
import { Helmet } from 'react-helmet-async';
import useLocale from '../Hooks/useLocale';
const SeoLanding = () => {
  const localeLocalization = useLocale()
  return localeLocalization == 'it' ? <ItalianCom /> : <EnglishCom />

}

export default SeoLanding;

const EnglishCom = () => {
  return <Helmet prioritizeSeoTags>
    <meta charSet="UTF-8" />
    <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
    <link rel="alternate" href="https://alysei.com/en" hreflang="en" />
    <link rel="alternate" href="https://alysei.com/it" hreflang="it" />
    {/* <!-- Primary Meta Tags --> */}
    <title>Alysei - Best Platform for Authentic Italian Food & Beverage Solutions in the USA</title>
    <meta name="description" content="Explore Alysei, the leading B2B platform for authentic Italian food and beverage products. Connect with producers, importers, and enthusiasts to promote and enjoy Made in Italy excellence in the USA." />
    <meta name="keywords" content="Italian Food and Beverage Platform USA, B2B Italian Food Solutions, B2C Italian Products Marketplace, Italian Food Distributors USA, Italian Culinary Products USA, Italian Gastronomy Network, Italian Food Importers and Distributors, Italian Food and Beverage Marketplace" />
    <link rel="canonical" href="https://alysei.com/en" />

    {/* <!-- Open Graph / Facebook --> */}
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Alysei - Best Platform for Authentic Italian Food & Beverage Solutions in the USA" />
    <meta property="og:description" content="Explore Alysei, the leading B2B platform for authentic Italian food and beverage products. Connect with producers, importers, and enthusiasts to promote and enjoy Made in Italy excellence in the USA." />
    <meta property="og:url" content="https://alysei.com/en" />
    <meta property="og:site_name" content="Alysei" />
    <meta property="og:image" content="https://alysei.com/dist/images/investment-img.png" />

    {/* <!-- Twitter --> */}
    <meta property="twitter:card" content="summary" />
    <meta property="twitter:url" content="https://alysei.com/en" />
    <meta property="twitter:title" content="Alysei - Best Platform for Authentic Italian Food & Beverage Solutions in the USA" />
    <meta property="twitter:description" content="Explore Alysei, the leading B2B platform for authentic Italian food and beverage products. Connect with producers, importers, and enthusiasts to promote and enjoy Made in Italy excellence in the USA." />
    <meta property="twitter:image" content="https://alysei.com/dist/images/investment-img.png" />

  </Helmet>
}

const ItalianCom = () => {
  return <Helmet prioritizeSeoTags>
    <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
    <link rel="alternate" href="https://alysei.com/en" hreflang="en" />
    <link rel="alternate" href="https://alysei.com/it" hreflang="it" />
    {/* <!-- Primary Meta Tags --> */}
    <title>Alysei | La Piattaforma per il Settore Food & Beverage Italiano</title>
    <meta name="description" content="Connetti produttori, distributori e professionisti del settore enogastronomico con Alysei. Scopri opportunità B2B esclusive per il cibo e le bevande italiane." />
    <link rel="canonical" href="https://alysei.com/it" />

    {/* <!-- Open Graph / Facebook --> */}
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Alysei | La Piattaforma per il Settore Food & Beverage Italiano" />
    <meta property="og:description" content="Connetti produttori, distributori e professionisti del settore enogastronomico con Alysei. Scopri opportunità B2B esclusive per il cibo e le bevande italiane." />
    <meta property="og:url" content="https://alysei.com/it" />
    <meta property="og:site_name" content="Alysei" />
    <meta property="og:image" content="https://alysei.com/dist/images/investment-img.png" />

    {/* <!-- Twitter --> */}
    <meta property="twitter:card" content="summary" />
    <meta property="twitter:url" content="https://alysei.com/it" />
    <meta property="twitter:title" content="Alysei | La Piattaforma per il Settore Food & Beverage Italiano" />
    <meta property="twitter:description" content="Connetti produttori, distributori e professionisti del settore enogastronomico con Alysei. Scopri opportunità B2B esclusive per il cibo e le bevande italiane." />
    <meta property="twitter:image" content="https://alysei.com/dist/images/investment-img.png" />

  </Helmet>
}