import React from 'react';
import { renderRoutes } from 'react-router-config';
import Root from '../layouts/Root';
import LoginView from '../routes/login/components/LoginView';
import { Redirect } from 'react-router';
import { AuthWrapperView } from './AuthWrapper/components/AuthWrapperView';
import AuthLayout from '../layouts/Authlayout';
import RegisterView from './register/components/RegisterView';
import { FormView } from './register/components/FormView';
import { HomeView } from './home/components/HomeView';
import { ProfileView } from './profile/components/ProfileView';
import CoreLayout from '../layouts/CoreLayout';
import { HubView } from './hubs/components/HubView';
import { SelectedHubs } from './hubs/components/SelectedHubs';
import { MarketplaceView } from './marketplace/components/MarketplaceView';
import AddProduct from './marketplace/components/AddProduct';
import GenerateTransactionReport from './marketplace/components/GenerateTransactionReport';
import TaxAdd from './marketplace/components/TaxAdd';
import CustomerManagementDetail from './marketplace/components/CustomerManagementDetail';
import { Dashboard } from './marketplace/components/Dashboard';
import { ThankYou } from './marketplace/components/ThankYou';
import { ConfigureYourStore } from './marketplace/components/ConfigureYourStore';
import { SelectPackageView } from './marketplace/components/SelectPackageView';
import RecipeView from './recipe/components/RecipeView';
import Times from './recipe/components/Times';
import AddGeneralinformation from './recipe/components/AddGeneralinformation';
import AddIngredients from './recipe/components/AddIngredients';
import AddUtencil from './recipe/components/AddUtencil';
import AddRecipeStep from './recipe/components/AddRecipeStep';
import { SelectedState } from './hubs/components/SelectedState';
import CitySelect from './hubs/components/CitySelect';
import CityName from './hubs/components/CityName';
import MarketPlaceLayout from '../layouts/MarketPlaceLayout';
import SendInquiryForm from './marketplace/components/SendInquiryForm';
import SearchProduct from './marketplace/components/SearchProduct';
import ProducerStore from './marketplace/components/ProducerStore';
import ProductView from './marketplace/components/ProductView';
// import StoreBox from "./marketplace/components/StoreBox";
import Connect from './recipe/Connect';
// import SettingBilling from "./recipe/components/SettingBilling";
// import B2b from "./recipe/components/B2b";
import B2B from './B2B/components/B2b';
// import YourData from "./recipe/components/YourData";
import MessageView from './message/components/MessageView';
import Setting from './setting/components/Setting';
import ChangeEmail from './Email/Components/ChangeEmail';
import ResetEmail from './Email/Components/ResetEmail';
import Privacy from './setting/components/Privacy';
import Password from './setting/components/Password';
import Company from './setting/components/Company';
import Blocking from './setting/components/Blocking';
import SettingYourData from './setting/components/SettingYourData';
import MemberShip from './setting/components/MemberShip';
import SettingBilling from './setting/components/SettingBilling';
import YourData from './setting/components/SettingYourData';
import ViewHub from './B2B/components/ViewHub';
import auth from '../helpers/auth';
import Discover from './discover/components/Discover';
import CreateBlog from './discover/components/CreateBlog';
import CreateAward from './discover/components/CreateAward';
import ViewBlog from './discover/components/ViewBlog';
import ViewTrip from './discover/components/ViewTrip';
import ViewEvent from './discover/components/ViewEvent';
import CreateEvent from './discover/components/CreateEvent';
import CreateTrip from './discover/components/CreateTrip';
import DiscoverBlog from './discover/components/DiscoverBlog';
import DiscoverRestaurants from './discover/components/DiscoverRestaurants';
import HubMember from './B2B/components/HubMember';
import Membership from './membership/components/Membership';
import SingleRecipeView from './recipe/components/SingleRecipeView';
import AddReview from './recipe/components/AddReview';
import Invitation from './NetworkConnection/Components/Invitation';
import Connection from './NetworkConnection/Components/Connection';
import Pending from './NetworkConnection/Components/Pending';
import Following from './NetworkConnection/Components/Following';
import CreateRecipeSteps from './recipe/components/CreateRecipeSteps';
import ViewRequest from './NetworkConnection/Components/ViewRequest';
import RecipeIngredientsList from './recipe/components/RecipeIngredientsList';
import EditProduct from './marketplace/components/EditProduct';
import { ErrorPage } from './marketplace/components/ErrorPage';
import { VisitorProfileView } from './profile/components/visitorProfileView';
import NewlyAddStore from './marketplace/components/NewlyAddedStore';
import UserCertificate from './NetworkConnection/Components/UserCertificates';
import HubsSelect from './setting/components/HubsSelect';
import ViewAllStoreAndProduct from './marketplace/components/ViewAllStoreAndProduct';
import ViewAward from './discover/components/ViewAward';
import UniversalSearch from './universal-search/components/UniversalSearch';
import FAQ from './setting/components/FAQ';
import NewsStatus from './discover/components/NewsStatus';
import NotificationView from './notification/components/NotificationView';
import OrderManagementDetails from './marketplace/components/OrderManagementDetails';
import CreateOffer from './marketplace/components/CreateOffer';
import { PostActivity } from './home/components/PostActivity';
import { ImporterInquiry } from './marketplace/components/ImporterInquiry';
import Access from './register/components/access';
import { PublicPostSingleViewPage } from './home/components/PublicPostSingleViewPage';
import TotalAddedProductListing from './marketplace/components/TotalAddedProductListing';
import MarketPlaceCheckout from './marketplace/components/MarketPlaceCheckout';
import MyDashboard from '../components/Dashboard/MyDashboard';
import SingleTransactionView from './marketplace/components/SingleTransactionView';
import SingleOrderViewPage from '../components/Dashboard/SingleOrderViewPage';
import NewBubblesComponentView from '../components/Bubbles/NewBubblesComponentView';
import PaymentSuccessfully from '../components/Dashboard/PaymentSuccessfully';
import SingleViewOfferDetails from '../components/Dashboard/SingleViewOfferDetails';
import { exact } from 'prop-types';
import SingleDisputeView from '../components/Dashboard/SingleDisputeView';
import DisputeManagementView from './marketplace/components/DisputeManagementView';
import BuyerDashboard from './Buyer/components/Dashboard';
import CreateRquest from './Buyer/components/CreateRquest';
import RecentRequests from './Buyer/components/RecentRequests';
import Notification from './Buyer/components/Notification';
import RecentRequestView from './Buyer/components/RecentRequestsView/RecentRequestView';
import BuyerThankYou from '../components/BuyerThankYou/ThankYou';
import ChangePassword from './Buyer/components/Settings/ChangePassword';
import ChangeContactInfo from './Buyer/components/Settings/ChangeContactInfo';
import DeleteAccount from './Buyer/components/Settings/DeleteAccount';
import Settings from './Buyer/components/Settings';
import Subscription from './setting/components/Subscription';
import SubscriptionModal from './setting/components/SubscriptionModal';
import LeadView from './marketplace/components/LeadView';
import BuyerNotificationView from './Buyer/components/BuyerNotificationView/BuyerNotificationView';
import Landing from './LandingPage';
import Contact from './LandingPage/component/Contact';
import LandingLayout from './LandingLayout/Landing';
import Services from './services';
import About from './about';
import Faq from './faq';
import SignIn from './signIn';
import SignUp from './signup';
import path from 'path-browserify';
import ShippingModule from './marketplace/components/ShippingModule';
import Unsubscribe from './LandingPage/component/Unsubscribe';
import PageNotFound from './404';
export const redirectRoute = (props) => {
  const token = auth.getAccessToken();
  if (token !== null) {
    let role = JSON.parse(localStorage.getItem('USER_TYPE'));
    if (role?.role_id === 11) {
      return <Redirect to="/buyer-dashboard" />;
    } else {
      return <Redirect to="/home" />;
    }
  }
  //  else {
  //return <Redirect to="/register" />;
  // return <Redirect to="/" />;
  // }
  // const accessCode = auth.getAccessCode();
  // if(accessCode === 'verified'){
  //   const token = auth.getAccessToken();
  //   if (token !== null) {
  //     return <Redirect to="/home" />;
  //   } else {
  //     return <Redirect to="/register" />;
  //   }
  // }
  // else{
  //   return <Redirect to="/access" />;
  // }
};
const UrlWithLocalization = (url) => {
  return `/en${url}`
}
const UrlWithLocalizationIt = (url) => {
  // return `/it${url}`
  return url;
}
const RedirectToNotFound = () => {
  // const newUrl = new URL(window.location);
  // let urlSlice = newUrl.pathname.split('/');
  // console.log('newUrlroutes',newUrl)
  // console.log('urlSlice',urlSlice)
  // if (urlSlice.length > 0 && (urlSlice[1] != 'en' && urlSlice[1] != 'it')) {
  //   let secLag = localStorage.getItem('lang') || 'en';

  return <Redirect to={`/404`} />;
  // }

}
export const routes = [
  {
    component: Root,
    routes: [
      {
        path: '/',
        component: LandingLayout,
        routes: [
          {
            path: '/',
            exact: true,
            component: Landing,
          },
          {
            path: '/en',
            exact: true,
            component: Landing,
          },
          {
            path: '/it',
            exact: true,
            component: Landing,
          },

          {
            path: "/404",
            exact: true,
            component: PageNotFound,
          },

          {
            path: "/services/:slug",
            exact: true,
            component: Services,
          },
          {
            path: '/about',
            exact: true,
            component: About,
          },



          // @@@START Route with localization
          {
            path: '/en/about',
            component: About,
          },
          {
            path: '/it/about',
            component: About,
          },

          {
            path: '/en/contact',
            component: Contact,
          },
          {
            path: '/it/contact',
            component: Contact,
          },

          {
            path: '/en/services/:slug',
            component: Services,
          },
          {
            path: '/it/services/:slug',
            component: Services,
          },

          {
            path: '/en/faq',
            component: Faq,
          },
          {
            path: '/it/faq',
            component: Faq,
          },

          {
            path: '/en/login',
            component: SignIn,
          },
          {
            path: '/it/login',
            component: SignIn,
          },

          {
            path: '/en/signup',
            component: SignUp,
          },
          {
            path: '/it/signup',
            component: SignUp,
          },

          {
            path: '/en/register',
            component: AuthLayout,
            routes: [
              {
                path: '/en/register',
                component: AuthWrapperView,
                routes: [
                  {
                    path: '/en/register',
                    exact: true,
                    component: RegisterView,
                  },
                  // {
                  //   path: '/register/shipping',
                  //   exact: true,
                  //   component: ShippingModule
                  // },
                  {
                    path: '/en/register/:role',
                    exact: true,
                    component: FormView,
                  },
                ],
              },
            ],
          },
          {
            path: '/it/register',
            component: AuthLayout,
            routes: [
              {
                path: '/it/register',
                component: AuthWrapperView,
                routes: [
                  {
                    path: '/it/register',
                    exact: true,
                    component: RegisterView,
                  },
                  {
                    path: '/it/register/:role',
                    exact: true,
                    component: FormView,
                  },
                ],
              },
            ],
          },




          // @@@END Route with localization
          {
            path: "/shipping-module",
            exact: true,
            component: ShippingModule
          },
          {
            path: '/faq',
            exact: true,
            component: Faq,
          },

          {
            path: '/contact',
            exact: true,
            component: Contact,
          },

          {
            path: '/unsubscribe',
            exact: true,
            component: Unsubscribe,
          },
          {
            path: '/login', //signin replace with login
            exact: true,
            component: SignIn,
          },

          {
            path: '/signup',
            exact: true,
            component: SignUp,
          },

          {
            path: '/register',
            component: AuthLayout,
            routes: [
              {
                path: '/register',
                component: AuthWrapperView,
                routes: [
                  {
                    path: '/register',
                    exact: true,
                    component: RegisterView,
                  },
                  {
                    path: '/register/shipping',
                    exact: true,
                    component: ShippingModule
                  },
                  {
                    path: '/register/:role',
                    exact: true,
                    component: FormView,
                  },
                ],
              },
            ],
          },
          {
            path: '/access',
            exact: true,
            component: Access,
          },
          // old login
          // {
          //   path: '/login',
          //   component: AuthLayout,
          //   routes: [
          //     {
          //       path: '/login',
          //       component: AuthWrapperView,
          //       routes: [
          //         {
          //           path: '/login',
          //           exact: true,
          //           component: LoginView,
          //         },
          //       ],
          //     },
          //   ],
          // },

          {
            path: '/my-dashboard',
            exact: true,
            component: CoreLayout,
            routes: [
              {
                path: '/my-dashboard',
                exact: true,
                component: MyDashboard,
              },
            ],
          },

          {
            path: '/order-payment-successfully-complete',
            exact: true,
            component: CoreLayout,
            routes: [
              {
                path: '/order-payment-successfully-complete',
                exact: true,
                component: PaymentSuccessfully,
              },
            ],
          },
          //Marketplace routes start
          {
            path: '/marketplace',
            component: CoreLayout,
            routes: [
              {
                path: '/marketplace',
                exact: true,
                component: MarketplaceView,
              },

              {
                path: '/marketplace/dashboard',
                exact: true,
                component: Dashboard,
              },

              {
                path: '/marketplace/thankyou',
                exact: true,
                component: ThankYou,
              },
              {
                path: '/marketplace/error',
                exact: true,
                component: ErrorPage,
              },
              {
                path: '/marketplace/send-inquiry/:id',
                exact: true,
                component: SendInquiryForm,
              },
              {
                path: '/marketplace/product/:slug',
                exact: true,
                component: ProductView,
              },
              {
                path: '/marketplace/configureyourstore',
                exact: true,
                component: ConfigureYourStore,
              },
              {
                path: '/marketplace/selectpackage',
                exact: true,
                component: SelectPackageView,
              },
              {
                path: '/marketplace/search',
                exact: true,
                component: SearchProduct,
              },
              {
                path: '/marketplace/producer-store/:id',
                exact: true,
                component: ProducerStore,
              },
              {
                path: '/marketplace/add-product',
                component: AddProduct,
              },
              {
                path: '/marketplace/generate-transaction-report',
                component: GenerateTransactionReport,
              },
              {
                path: '/marketplace/add-tax',
                component: TaxAdd,
              },
              {
                path: '/marketplace/edit-tax/:id',
                component: TaxAdd,
              },
              {
                path: '/marketplace/edit-tax-classes/:id',
                component: TaxAdd,
              },
              {
                path: '/marketplace/add-tax-classes',
                component: TaxAdd,
              },
              {
                path: '/marketplace/order-management-detail',
                component: OrderManagementDetails,
              },
              {
                path: '/marketplace/create-offer',
                component: CreateOffer,
              },
              {
                path: '/marketplace/edit-offer/:id',
                component: CreateOffer,
              },
              {
                path: '/marketplace/customer-management-view/:id',
                component: CustomerManagementDetail,
              },
              {
                path: '/marketplace/edit-product/:id',
                component: EditProduct,
              },
              {
                path: '/marketplace/view-all/:type/:id',
                component: ViewAllStoreAndProduct,
              },
              {
                path: '/marketplace/added-store/:id',
                component: NewlyAddStore,
              },
              {
                path: '/marketplace/my-cart',
                component: TotalAddedProductListing,
              },
              {
                path: '/marketplace/checkout-form',
                component: MarketPlaceCheckout,
              },
              {
                path: '/marketplace/dashboard',
                exact: true,
                component: Dashboard,
              },
              {
                path: '/marketplace/my-order-view/:id',
                exact: true,
                component: SingleOrderViewPage,
              },
              {
                path: '/marketplace/producer-my-order-view/:id',
                exact: true,
                component: OrderManagementDetails,
              },
              {
                path: '/marketplace/producer-transaction-view/:id',
                exact: true,
                component: SingleTransactionView,
              },
              {
                path: '/marketplace/offer/:offerId',
                exact: true,
                component: SingleViewOfferDetails,
              },
              {
                path: '/marketplace/inquiry',
                exact: true,
                component: ImporterInquiry,
              },
              {
                path: '/marketplace/view-dispute/:disputeId',
                exact: true,
                component: SingleDisputeView,
              },
              {
                path: '/marketplace/dispute-management-view',
                exact: true,
                component: DisputeManagementView,
              },
              {
                path: '/marketplace/view-lead/:uuid',
                exact: true,
                component: LeadView,
              },
            ],
          },
          //Marketplace routes end
          {
            path: '/home/post',
            component: CoreLayout,
            routes: [
              {
                path: '/home/post/activity/:activityId',
                component: PostActivity,
              },
            ],
          },
          {
            path: '/home/public/post',
            component: AuthWrapperView,
            routes: [
              {
                path: '/home/public/post/activity/:activityId',
                component: PublicPostSingleViewPage,
              },
            ],
          },

          {
            path: '/home',
            component: CoreLayout,
            routes: [
              {
                path: '/home',
                component: HomeView,
              },
            ],
          },

          {
            path: '/universal/search',
            component: UniversalSearch,
          },

          {
            path: '/discover',
            component: CoreLayout,
            routes: [
              {
                path: '/discover/:name',
                component: Discover,
              },
            ],
          },
          {
            path: '/create-blog',
            component: CoreLayout,
            routes: [
              {
                path: '/create-blog',
                component: CreateBlog,
              },
            ],
          },
          {
            path: '/edit-blog',
            component: CoreLayout,
            routes: [
              {
                path: '/edit-blog/:id',
                component: CreateBlog,
              },
            ],
          },
          {
            path: '/create-event',
            component: CoreLayout,
            routes: [
              {
                path: '/create-event',
                component: CreateEvent,
              },
            ],
          },

          {
            path: '/edit-event',
            component: CoreLayout,
            routes: [
              {
                path: '/edit-event/:id',
                component: CreateEvent,
              },
            ],
          },

          {
            path: '/create-trips',
            component: CoreLayout,
            routes: [
              {
                path: '/create-trips',
                component: CreateTrip,
              },
            ],
          },
          {
            path: '/edit-trips',
            component: CoreLayout,
            routes: [
              {
                path: '/edit-trips/:id',
                component: CreateTrip,
              },
            ],
          },
          {
            path: '/view-trips',
            component: CoreLayout,
            routes: [
              {
                path: '/view-trips/:id',
                component: ViewTrip,
              },
            ],
          },
          {
            path: '/create-award',
            component: CoreLayout,
            routes: [
              {
                path: '/create-award',
                component: CreateAward,
              },
            ],
          },
          {
            path: '/view-award',
            component: CoreLayout,
            routes: [
              {
                path: '/view-award/:id',
                component: ViewAward,
              },
            ],
          },
          {
            path: '/edit-create-award',
            component: CoreLayout,
            routes: [
              {
                path: '/edit-create-award/:id',
                component: CreateAward,
              },
            ],
          },
          {
            path: '/view-blogs',
            component: CoreLayout,
            routes: [
              {
                path: '/view-blogs/:blogId/:id',
                component: ViewBlog,
              },
            ],
          },
          {
            path: '/view-events',
            component: CoreLayout,
            routes: [
              {
                path: '/view-events/:id',
                component: ViewEvent,
              },
            ],
          },
          {
            path: '/discoverblog',
            component: CoreLayout,
            routes: [
              {
                path: '/discoverblog',
                component: DiscoverBlog,
              },
            ],
          },
          {
            path: '/discoverrestaurants',
            component: CoreLayout,
            routes: [
              {
                path: '/discoverrestaurants',
                component: DiscoverRestaurants,
              },
            ],
          },
          {
            path: '/discovery-alysei',
            component: CoreLayout,
            routes: [
              {
                path: '/discovery-alysei/:slug',
                component: NewBubblesComponentView,
              },
            ],
          },
          {
            path: '/profile',
            component: CoreLayout,
            routes: [
              {
                path: '/profile',
                component: ProfileView,
              },
            ],
          },
          {
            path: '/visitor-profile',
            component: CoreLayout,
            routes: [
              {
                path: '/visitor-profile/:id',
                component: VisitorProfileView,
              },
            ],
          },
          {
            path: '/recipe/search/',
            component: CoreLayout,
            routes: [
              {
                path: '/recipe/search/:slug/:id',
                component: Times,
              },
            ],
          },

          {
            path: '/recipes/create-recipe',
            component: CoreLayout,
            routes: [
              {
                path: '/recipes/create-recipe',
                component: AddGeneralinformation,
              },
            ],
          },
          {
            path: '/recipes/add-ingredients',
            component: CoreLayout,
            routes: [
              {
                path: '/recipes/add-ingredients',
                component: AddIngredients,
              },
            ],
          },
          {
            path: '/Alysei-news',
            component: NewsStatus,
          },
          {
            path: '/recipes/add-untencil',
            component: CoreLayout,
            routes: [
              {
                path: '/recipes/add-untencil',
                component: AddUtencil,
              },
            ],
          },
          {
            path: '/recipes/recipe-steps',
            component: CoreLayout,
            routes: [
              {
                path: '/recipes/recipe-steps',
                component: AddRecipeStep,
              },
            ],
          },
          {
            path: '/recipes',
            component: CoreLayout,
            routes: [
              {
                path: '/recipes',
                exact: true,
                component: RecipeView,
              },
              {
                path: '/recipes/create',
                exact: true,
                component: AddGeneralinformation,
              },
              {
                path: '/recipes/edit-recipe',
                exact: true,
                component: AddGeneralinformation,
              },
              {
                path: '/recipes/edit/ingredients/:slug',
                exact: true,
                component: AddIngredients,
              },
              {
                path: '/recipes/recipeSingle/:slug',
                exact: true,
                component: SingleRecipeView,
              },
              {
                path: '/recipes/addreview/:slug',
                exact: true,
                component: AddReview,
              },

              {
                path: '/recipes/recipe-view/:slug',
                exact: true,
                component: SingleRecipeView,
              },
              {
                path: '/recipes/create-recipe-steps/:slug',
                exact: true,
                component: CreateRecipeSteps,
              },
            ],
          },

          {
            path: '/settingbilling',
            component: CoreLayout,
            routes: [
              {
                path: '/settingbilling',
                component: SettingBilling,
              },
            ],
          },
          {
            path: '/connect',
            component: CoreLayout,
            routes: [
              {
                path: '/connect',
                component: Connect,
              },
            ],
          },
          {
            path: '/b2b',
            component: CoreLayout,
            routes: [
              {
                path: '/b2b',
                component: B2B,
              },
            ],
          },

          // {
          //   path: "/addlist",
          //   component: CoreLayout,
          //   routes: [
          //     {
          //       path: "/addlist",
          //       component: AddIngredients,
          //     },
          //   ],
          // },
          // {
          //   path: "/adduntencil",
          //   component: CoreLayout,
          //   routes: [
          //     {
          //       path: "/adduntencil",
          //       component: AddUtencil,
          //     },
          //   ],
          // },
          {
            path: '/addrecipestep',
            component: CoreLayout,
            routes: [
              {
                path: '/addrecipestep',
                component: AddRecipeStep,
              },
            ],
          },

          {
            path: '/yourdata',
            component: CoreLayout,
            routes: [
              {
                path: '/yourdata',
                component: YourData,
              },
            ],
          },

          {
            path: '/setting',
            component: CoreLayout,
            routes: [
              {
                path: '/setting',
                component: Setting,
              },
            ],
          },

          {
            path: '/change-email',
            component: CoreLayout,
            routes: [
              {
                path: '/change-email',
                component: ChangeEmail,
              },
            ],
          },

          {
            path: '/reset-email',
            component: CoreLayout,
            routes: [
              {
                path: '/reset-email',
                component: ResetEmail,
              },
            ],
          },
          {
            path: '/faqs',
            component: CoreLayout,
            routes: [
              {
                path: '/faqs',
                component: FAQ,
              },
            ],
          },
          {
            path: '/hubselect',
            component: AuthWrapperView,
            routes: [
              {
                path: '/hubselect',
                component: HubsSelect,
              },
            ],
          },
          // {
          //   path: '/marketplace',
          //   exact: true,
          //   component: CoreLayout,
          //   routes: [
          //     {
          //       path: '/marketplace',
          //       component: MarketplaceView,
          //     },
          //   ],
          // },
          {
            path: '/user-profile',
            component: CoreLayout,
            routes: [
              {
                path: '/user-profile/:id',
                component: VisitorProfileView,
              },
            ],
          },
          {
            path: '/viewhub',
            component: CoreLayout,
            routes: [
              {
                path: '/viewhub/:id',
                component: ViewHub,
              },
            ],
          },
          {
            path: '/notification',
            component: CoreLayout,
            routes: [
              {
                path: '/notification',
                component: NotificationView,
              },
            ],
          },

          {
            path: '/my-store',
            component: MarketPlaceLayout,
            routes: [
              {
                path: '/my-store',
                component: NewlyAddStore,
              },
            ],
          },

          {
            path: '/privacy',
            component: CoreLayout,
            routes: [
              {
                path: '/privacy',
                component: Privacy,
              },
            ],
          },
          {
            path: '/password',
            component: CoreLayout,
            routes: [
              {
                path: '/password',
                component: Password,
              },
            ],
          },
          {
            path: '/company',
            component: CoreLayout,
            routes: [
              {
                path: '/company',
                component: Company,
              },
            ],
          },
          {
            path: '/blocking',
            component: CoreLayout,
            routes: [
              {
                path: '/blocking',
                component: Blocking,
              },
            ],
          },
          {
            path: '/setting-membership',
            component: CoreLayout,
            routes: [
              {
                path: '/setting-membership',
                component: MemberShip,
              },
            ],
          },
          {
            path: '/subscription',
            component: CoreLayout,
            routes: [
              {
                path: '/subscription',
                component: Subscription,
              },
            ],
          },
          {
            path: '/subscription-modal',
            component: CoreLayout,
            routes: [
              {
                path: '/subscription-modal',
                component: SubscriptionModal,
              },
            ],
          },
          // {
          //   path: "/billing",
          //   component: CoreLayout,
          //   routes: [
          //     {
          //       path: "/billing",
          //       component: Billing,
          //     },
          //   ],
          // },
          {
            path: '/your-data',
            component: CoreLayout,
            routes: [
              {
                path: '/your-data',
                component: SettingYourData,
              },
            ],
          },
          {
            path: '/message',
            component: CoreLayout,
            routes: [
              {
                path: '/message',
                component: MessageView,
              },
            ],
          },

          {
            path: '/marketplace/configure-store',
            component: MarketPlaceLayout,
            routes: [
              {
                path: '/marketplace/configure-store',
                component: ConfigureYourStore,
              },
            ],
          },

          {
            path: '/marketplace/package',
            component: MarketPlaceLayout,
            routes: [
              {
                path: '/marketplace/package',
                component: SelectPackageView,
              },
            ],
          },

          {
            path: '/marketplace/addproduct',
            component: MarketPlaceLayout,
            routes: [
              {
                path: '/marketplace/addproduct',
                component: AddProduct,
              },
            ],
          },

          // {
          //   path: '/marketplace',
          //   exact: true,
          //   component: CoreLayout,
          //   routes: [
          //     {
          //       path: '/marketplace',
          //       component: MarketplaceView,
          //     },
          //   ],
          // },

          {
            path: '/product',
            component: MarketPlaceLayout,
            routes: [
              {
                path: '/product/:id',
                component: MarketplaceView,
              },
            ],
          },
          {
            path: '/hubs',
            component: AuthWrapperView,
            routes: [
              {
                path: '/hubs',
                component: HubView,
              },
            ],
          },

          {
            path: '/edit-hubs',
            component: AuthWrapperView,
            routes: [
              {
                path: '/edit-hubs',
                component: CityName,
              },
            ],
          },

          {
            path: '/selected_hubs',
            component: AuthWrapperView,
            routes: [
              {
                path: '/selected_hubs',
                component: SelectedHubs,
              },
            ],
          },

          {
            path: '/selectedState',
            component: AuthWrapperView,
            routes: [
              {
                path: '/selectedState',
                component: SelectedState,
              },
            ],
          },
          {
            path: '/city',
            component: AuthWrapperView,
            routes: [
              {
                path: '/city',
                component: CitySelect,
              },
            ],
          },

          {
            path: '/hubmember',
            component: CoreLayout,
            routes: [
              {
                path: '/hubmember/:hubId/:roleId',
                component: HubMember,
              },
            ],
          },
          {
            path: '/membership',
            component: CoreLayout,
            routes: [
              {
                path: '/membership',
                component: Membership,
              },
            ],
          },
          // {
          //   path: "/recipe",
          //   component: CoreLayout,
          //   routes: [
          //     {
          //       path: "/recipe/:slug",
          //       component: SingleRecipeView
          //     },
          //   ],
          // },
          {
            path: '/recipelist',
            component: CoreLayout,
            routes: [
              {
                path: '/recipelist',
                component: RecipeIngredientsList,
              },
            ],
          },
          // {
          //   path: "/addreview",
          //   component: CoreLayout,
          //   routes: [
          //     {
          //       path: "/addreview",
          //       component: AddReview
          //     },
          //   ],
          // },
          {
            path: '/networking',
            component: CoreLayout,
            routes: [
              {
                path: '/networking',
                component: Invitation,
              },
            ],
          },
          {
            path: '/connection',
            component: CoreLayout,
            routes: [
              {
                path: '/connection',
                component: Connection,
              },
            ],
          },
          {
            path: '/pending',
            component: CoreLayout,
            routes: [
              {
                path: '/pending',
                component: Pending,
              },
            ],
          },
          {
            path: '/following',
            component: CoreLayout,
            routes: [
              {
                path: '/following',
                component: Following,
              },
            ],
          },
          // {
          //   path: "/createrecipesteps",
          //   component: CoreLayout,
          //   routes: [
          //     {
          //       path: "/createrecipesteps",
          //       component: CreateRecipeSteps
          //     },
          //   ],
          // },
          {
            path: '/view-request',
            component: CoreLayout,
            routes: [
              {
                path: '/view-request/:connection_id/:resource_id/:user_id',
                component: ViewRequest,
              },
            ],
          },
          {
            path: '/user-certification',
            component: CoreLayout,
            routes: [
              {
                path: '/user-certification/:slug/:id',
                component: UserCertificate,
              },
            ],
          },
          {
            path: '/buyer-dashboard',
            component: Root,
            routes: [
              {
                path: '/buyer-dashboard',
                component: BuyerDashboard,
              },
            ],
          },
          {
            path: '/buyer-thank-you',
            component: Root,
            routes: [
              {
                path: '/buyer-thank-you',
                component: BuyerThankYou,
              },
            ],
          },
          {
            path: '/create-request',
            component: Root,
            routes: [
              {
                path: '/create-request',
                component: CreateRquest,
              },
            ],
          },

          {
            path: '/recent-request',
            component: Root,
            routes: [
              {
                path: '/recent-request',
                component: RecentRequests,
              },
            ],
          },
          {
            path: '/view-recent-request',
            component: Root,
            routes: [
              {
                path: '/view-recent-request/:uuid',
                component: RecentRequestView,
              },
            ],
          },
          {
            path: '/buyer-notification',
            component: Root,
            routes: [
              {
                path: '/buyer-notification',
                component: BuyerNotificationView,
              },
            ],
          },
          // {
          //   path: '/buyer-notification',
          //   component: Root,
          //   routes: [
          //     {
          //       path: '/buyer-notification',
          //       component: Notification,
          //     },
          //   ],
          // },
          {
            path: '/buyer-settings',
            component: Root,
            routes: [
              {
                path: '/buyer-settings',
                component: Settings,
              },
            ],
          },
          {
            path: '/change-password',
            component: Root,
            routes: [
              {
                path: '/change-password',
                component: ChangePassword,
              },
            ],
          },
          {
            path: '/change-contact-info',
            component: Root,
            routes: [
              {
                path: '/change-contact-info',
                component: ChangeContactInfo,
              },
            ],
          },
          {
            path: '/delete-account',
            component: Root,
            routes: [
              {
                path: '/delete-account',
                component: DeleteAccount,
              },
            ],
          },

          {
            path: "*",
            component: RedirectToNotFound,
          },

        ],
      },



    ],
  },
];

export const createRoutes = () => {
  return renderRoutes(routes);
};

export default createRoutes;
