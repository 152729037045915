import React from 'react'
// import { Helmet } from 'react-helmet'
import { Helmet } from 'react-helmet-async'
import useLocale from '../../Hooks/useLocale'
const ItalianFood = () => {
  const localeLocalization = useLocale()
  return localeLocalization == 'it' ? <ItalianCom /> : <EnglishCom />

}

export default ItalianFood;
const EnglishCom = () => {
  return <Helmet prioritizeSeoTags>
    <meta charSet="UTF-8" />
    <meta name="robots" content="noindex, nofollow" />
    <link rel="alternate" href="https://alysei.com/en/register/italian-food-beverage-producers" hreflang="en" />
    <link rel="alternate" href="https://alysei.com/it/register/italian-food-beverage-producers" hreflang="it" />

    {/* <!-- Primary Meta Tags --> */}

    <title>Register as Italian Food & Beverage Producers USA | Alysei</title>
    <meta name="description" content="Join Alysei as an Italian food and beverage producer to connect with importers, distributors, and buyers in the USA. Grow your brand and reach new markets." />
    <meta name="keywords" content="register Italian food producer USA, Italian beverage producer USA, Italian food distributors USA, Italian beverage distributors USA, food producers USA, beverage producers USA, Alysei Italian producers USA, Italian food and beverage market USA, connect with importers USA, expand brand USA" />
    <link rel="canonical" href="https://alysei.com/en/register/italian-food-beverage-producers" />

    {/* <!-- Open Graph / Facebook --> */}
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Register as Italian Food & Beverage Producers USA | Alysei" />
    <meta property="og:description" content="Join Alysei as an Italian food and beverage producer to connect with importers, distributors, and buyers in the USA. Grow your brand and reach new markets." />
    <meta property="og:url" content="https://alysei.com/en/register/italian-food-beverage-producers" />
    <meta property="og:site_name" content="Alysei" />
    <meta property="og:image" content="https://alysei.com/dist/images/app-mockup.png" />

    {/* <!-- Twitter --> */}
    <meta property="twitter:card" content="summary" />
    <meta property="twitter:url" content="https://alysei.com/en/register/italian-food-beverage-producers" />
    <meta property="twitter:title" content="Register as Italian Food & Beverage Producers USA | Alysei" />
    <meta property="twitter:description" content="Join Alysei as an Italian food and beverage producer to connect with importers, distributors, and buyers in the USA. Grow your brand and reach new markets." />
    <meta property="twitter:image" content="https://alysei.com/dist/images/app-mockup.png" />

  </Helmet>
}

const ItalianCom = () => {
  return <Helmet prioritizeSeoTags>
    <meta name="robots" content="noindex, nofollow" />
    <link rel="alternate" href="https://alysei.com/en/register/italian-food-beverage-producers" hreflang="en" />
    <link rel="alternate" href="https://alysei.com/it/register/italian-food-beverage-producers" hreflang="it" />
    {/* <!-- Primary Meta Tags --> */}
    <title>Registrati come Produttore di Cibo e Bevande Italiane | Alysei</title>
    <meta name="description" content="Unisciti ad Alysei come produttore di cibo e bevande italiane per connetterti con importatori, distributori e acquirenti negli USA. Espandi il tuo brand e raggiungi nuovi mercati!" />
    <link rel="canonical" href="https://alysei.com/it/register/italian-food-beverage-producers" />

    {/* <!-- Open Graph / Facebook --> */}
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Registrati come Produttore di Cibo e Bevande Italiane | Alysei" />
    <meta property="og:description" content="Unisciti ad Alysei come produttore di cibo e bevande italiane per connetterti con importatori, distributori e acquirenti negli USA. Espandi il tuo brand e raggiungi nuovi mercati!" />
    <meta property="og:url" content="https://alysei.com/it/register/italian-food-beverage-producers" />
    <meta property="og:site_name" content="Alysei" />
    <meta property="og:image" content="https://alysei.com/dist/images/app-mockup.png" />

    {/* <!-- Twitter --> */}
    <meta property="twitter:card" content="summary" />
    <meta property="twitter:url" content="https://alysei.com/it/register/italian-food-beverage-producers" />
    <meta property="twitter:title" content="Registrati come Produttore di Cibo e Bevande Italiane | Alysei" />
    <meta property="twitter:description" content="Unisciti ad Alysei come produttore di cibo e bevande italiane per connetterti con importatori, distributori e acquirenti negli USA. Espandi il tuo brand e raggiungi nuovi mercati!" />
    <meta property="twitter:image" content="https://alysei.com/dist/images/app-mockup.png" />

  </Helmet>
}