import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

const withLocalization = (Component) => {
  return (props) => {
    const location = useLocation();
    const history = useHistory();
    const [localeLang, setLocaleLang] = useState('');

    useEffect(() => {
      let secLag = localStorage.getItem('lang') || 'en';
      secLag = (secLag == 'en' || secLag == 'it') ? secLag : 'en'
      setLocaleLang(secLag);
      const newUrl = new URL(window.location);
      // console.log("newUrl", newUrl)
      let urlSlice = newUrl.pathname.split('/');
      // console.log('urlSlice', urlSlice, secLag);
      if (urlSlice.length > 0 && (urlSlice[1] == 'en' || urlSlice[1] == 'it')) {
        // setIsComponentReturn(true)
        if (urlSlice[1] != secLag) {
          // urlSlice[1] = secLag;
          sessionStorage.setItem("lang", urlSlice[1]);
          localStorage.setItem("lang", urlSlice[1]);
          let joinUrl = urlSlice.join('/')
          // history.push(joinUrl);
          // window.location.reload();
          window.location.replace(joinUrl)
        }
      } else if (newUrl.pathname == '/') {
        sessionStorage.setItem('lang', secLag);
        localStorage.setItem("lang",secLag);
        urlSlice[1] = secLag;
        let joinUrl = urlSlice.join('/')
        history.push(joinUrl);
      }

    }, [history, location]);

    return <Component {...props} localeLang={localeLang} />
  };
};

export default withLocalization;
