import React from "react";
// import { Helmet } from "react-helmet"
import { Helmet } from "react-helmet-async";
import useLocale from "../../Hooks/useLocale";
const ImporterDistributers = () => {
    const localeLocalization = useLocale();

    return localeLocalization == 'it' ? <ItalianCom /> : <EnglishCom />
}

export default ImporterDistributers;

const EnglishCom = () => {
    return <>
       <Helmet prioritizeSeoTags>

            {/* Charset */}
            <meta charSet="UTF-8" />

            {/* Robots Meta Tag */}
            <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />

            {/* Alternate Language URLs */}
            <link rel="alternate" href="https://alysei.com/en/services/impoters-distributers" hreflang="en" />
            <link rel="alternate" href="https://alysei.com/it/services/impoters-distributers" hreflang="it" />

            {/* <!-- Primary Meta Tags --> */}
            <title>Top Italian Food Importers & Distributors USA | Alysei</title>
            <meta name="description" content="Connect with leading Italian food and beverage importers and distributors in the USA. Explore wholesale Italian product solutions, including food, wine, and beverages for your business with Alysei." />
            <meta name="keywords" content="Italian food importers USA, Italian beverage distributors USA, Italian food distribution services USA, Italian product importers USA, Wholesale Italian food distributors USA, Italian wine importers, Italian food suppliers USA, Importers of Italian food and beverages USA, Italian product distributors USA, Italian food wholesale distributors USA" />
            <link rel="canonical" href="https://alysei.com/en/services/importers-distributors" />

            {/* <!-- Open Graph / Facebook --> */}
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Top Italian Food Importers & Distributors USA | Alysei" />
            <meta property="og:description" content="Connect with leading Italian food and beverage importers and distributors in the USA. Explore wholesale Italian product solutions, including food, wine, and beverages for your business with Alysei." />
            <meta property="og:url" content="https://alysei.com/en/services/importers-distributors" />
            <meta property="og:site_name" content="Alysei" />
            <meta property="og:image" content="https://alysei.com/dist/images/italian-thumbnail.png" />

            {/* <!-- Twitter --> */}
            <meta property="twitter:card" content="summary" />
            <meta property="twitter:url" content="https://alysei.com/en/services/importers-distributors" />
            <meta property="twitter:title" content="Top Italian Food Importers & Distributors USA | Alysei" />
            <meta property="twitter:description" content="Connect with leading Italian food and beverage importers and distributors in the USA. Explore wholesale Italian product solutions, including food, wine, and beverages for your business with Alysei." />
            <meta property="twitter:image" content="https://alysei.com/dist/images/italian-thumbnail.png" />

        </Helmet>
    </>
}
const ItalianCom = () => {
    return <Helmet prioritizeSeoTags>
        <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />

        <link rel="alternate" href="https://alysei.com/en/services/impoters-distributers" hreflang="en" />
        <link rel="alternate" href="https://alysei.com/it/services/impoters-distributers" hreflang="it" />

        {/* <!-- Primary Meta Tags --> */}
        <title>Importatori e Distributori di Cibo e Bevande Italiane | Alysei</title>
        <meta name="description" content="Trova importatori e distributori di cibo e bevande italiane su Alysei. Scopri fornitori affidabili per la distribuzione all’ingrosso di prodotti autentici, vino e specialità culinarie." />
        <link rel="canonical" href="https://alysei.com/it/services/importers-distributers" />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Importatori e Distributori di Cibo e Bevande Italiane | Alysei" />
        <meta property="og:description" content="Trova importatori e distributori di cibo e bevande italiane su Alysei. Scopri fornitori affidabili per la distribuzione all’ingrosso di prodotti autentici, vino e specialità culinarie." />
        <meta property="og:url" content="https://alysei.com/it/services/importers-distributers" />
        <meta property="og:site_name" content="Alysei" />
        <meta property="og:image" content="https://alysei.com/dist/images/italian-thumbnail.png" />

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:url" content="https://alysei.com/it/services/importers-distributers" />
        <meta property="twitter:title" content="Importatori e Distributori di Cibo e Bevande Italiane | Alysei" />
        <meta property="twitter:description" content="Trova importatori e distributori di cibo e bevande italiane su Alysei. Scopri fornitori affidabili per la distribuzione all’ingrosso di prodotti autentici, vino e specialità culinarie." />
        <meta property="twitter:image" content="https://alysei.com/dist/images/italian-thumbnail.png" />

    </Helmet>
}