import React from 'react'
// import { Helmet } from 'react-helmet'
import { Helmet } from 'react-helmet-async';
import useLocale from '../../Hooks/useLocale'
const Voyagers = () => {
    const localeLocalization = useLocale();
    return localeLocalization == 'it' ? <ItalianCom /> : <EnglishCom />
}
export default Voyagers

const EnglishCom = () => {
    return <Helmet prioritizeSeoTags>
        <meta charSet="UTF-8" />
        <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
        <link rel="alternate" href="https://alysei.com/en/services/voyagers" hreflang="en" />
        <link rel="alternate" href="https://alysei.com/it/services/voyagers" hreflang="it" />

        {/* <!-- Primary Meta Tags --> */}
        <title>Best Italian Cuisine & Culinary Adventures USA | Alysei</title>
        <meta name="description" content="Join a vibrant community dedicated to Italian gastronomy with Alysei. Explore authentic recipes, cooking tips, food photography, and stay updated on Italian food festivals and trends for an unforgettable culinary journey." />
        <meta name="keywords" content="Italian Culinary Voyagers, Italian Gastronomy Exploration USA, Italian Cuisine Enthusiasts Platform, Italian Gastronomic Experiences, Italian Food Tourism USA, Culinary Travelers Italy, Italian Food and Beverage Voyages, Italian Gastronomy Community USA" />
        <link rel="canonical" href="https://alysei.com/en/services/voyagers" />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Best Italian Cuisine & Culinary Adventures USA | Alysei" />
        <meta property="og:description" content="Join a vibrant community dedicated to Italian gastronomy with Alysei. Explore authentic recipes, cooking tips, food photography, and stay updated on Italian food festivals and trends for an unforgettable culinary journey." />
        <meta property="og:url" content="https://alysei.com/en/services/voyagers" />
        <meta property="og:site_name" content="Alysei" />
        <meta property="og:image" content="https://alysei.com/dist/images/voyager-contentimg.png" />

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:url" content="https://alysei.com/en/services/voyagers" />
        <meta property="twitter:title" content="Best Italian Cuisine & Culinary Adventures USA | Alysei" />
        <meta property="twitter:description" content="Join a vibrant community dedicated to Italian gastronomy with Alysei. Explore authentic recipes, cooking tips, food photography, and stay updated on Italian food festivals and trends for an unforgettable culinary journey." />
        <meta property="twitter:image" content="https://alysei.com/dist/images/voyager-contentimg.png" />

    </Helmet>
}
const ItalianCom = () => {
    return <Helmet prioritizeSeoTags>

        <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
        <link rel="alternate" href="https://alysei.com/en/services/voyagers" hreflang="en" />
        <link rel="alternate" href="https://alysei.com/it/services/voyagers" hreflang="it" />
        {/* <!-- Primary Meta Tags --> */}
        <title>Community Voce degli Esperti | Alysei</title>
        <meta name="description" content="Unisciti alla Community Voce degli Esperti di Alysei! Chef, scuole di cucina, blogger e specialisti del settore food & beverage possono promuovere il loro brand, eventi e pubblicazioni." />
        <link rel="canonical" href="https://alysei.com/it/services/voyagers" />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Community Voce degli Esperti | Alysei" />
        <meta property="og:description" content="Unisciti alla Community Voce degli Esperti di Alysei! Chef, scuole di cucina, blogger e specialisti del settore food & beverage possono promuovere il loro brand, eventi e pubblicazioni." />
        <meta property="og:url" content="https://alysei.com/it/services/voyagers" />
        <meta property="og:site_name" content="Alysei" />
        <meta property="og:image" content="https://alysei.com/dist/images/experts-community.png" />

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:url" content="https://alysei.com/it/services/voyagers" />
        <meta property="twitter:title" content="Community Voce degli Esperti | Alysei" />
        <meta property="twitter:description" content="Unisciti alla Community Voce degli Esperti di Alysei! Chef, scuole di cucina, blogger e specialisti del settore food & beverage possono promuovere il loro brand, eventi e pubblicazioni." />
        <meta property="twitter:image" content="https://alysei.com/dist/images/experts-community.png" />


    </Helmet>
}