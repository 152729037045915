import React from 'react'
// import { Helmet } from 'react-helmet'
import { Helmet } from 'react-helmet-async';
import useLocale from '../../Hooks/useLocale'
const ItalianRestaurant = () => {
  const localeLocalization = useLocale();
  return localeLocalization == 'it' ? <ItalianCom /> : <EnglishCom />

}

export default ItalianRestaurant

const EnglishCom = () => {
  return <Helmet prioritizeSeoTags>
    <meta charSet="UTF-8" />
    <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
    <link rel="alternate" href="https://alysei.com/en/services/italian-restaurants" hreflang="en" />
    <link rel="alternate" href="https://alysei.com/it/services/italian-restaurants" hreflang="it" />

    {/* <!-- Primary Meta Tags --> */}
    <title>Best Italian Restaurants Supplies USA | Alysei</title>
    <meta name="description" content="Explore top-quality Italian restaurant supplies in the USA with Alysei. Source authentic ingredients, wines, and bulk Italian food products to elevate your restaurant's dining experience." />
    <meta name="keywords" content="Italian restaurants USA, Italian restaurant suppliers USA, Authentic Italian food for restaurants USA, Italian cuisine suppliers USA, Italian food wholesale for restaurants USA, Italian restaurant food distributors USA, Bulk Italian food for restaurants USA, Italian wine for restaurants USA, Italian food products for restaurants USA" />
    <link rel="canonical" href="https://alysei.com/en/services/italian-restaurants" />

    {/* <!-- Open Graph / Facebook --> */}
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Best Italian Restaurants Supplies USA | Alysei" />
    <meta property="og:description" content="Explore top-quality Italian restaurant supplies in the USA with Alysei. Source authentic ingredients, wines, and bulk Italian food products to elevate your restaurant's dining experience." />
    <meta property="og:url" content="https://alysei.com/en/services/italian-restaurants" />
    <meta property="og:site_name" content="Alysei" />
    <meta property="og:image" content="https://alysei.com/dist/images/restaurent-contentimg.png" />

    {/* <!-- Twitter --> */}
    <meta property="twitter:card" content="summary" />
    <meta property="twitter:url" content="https://alysei.com/en/services/italian-restaurants" />
    <meta property="twitter:title" content="Best Italian Restaurants Supplies USA | Alysei" />
    <meta property="twitter:description" content="Explore top-quality Italian restaurant supplies in the USA with Alysei. Source authentic ingredients, wines, and bulk Italian food products to elevate your restaurant's dining experience." />
    <meta property="twitter:image" content="https://alysei.com/dist/images/restaurent-contentimg.png" />

  </Helmet>
}

const ItalianCom = () => {
  return <Helmet prioritizeSeoTags>
    <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
    <link rel="alternate" href="https://alysei.com/en/services/italian-restaurants" hreflang="en" />
    <link rel="alternate" href="https://alysei.com/it/services/italian-restaurants" hreflang="it" />

    {/* <!-- Primary Meta Tags --> */}
    <title>Fornitori per Ristoranti Italiani | Alysei</title>
    <meta name="description" content="Scopri i migliori fornitori per ristoranti italiani su Alysei. Trova cibo autentico, vino e prodotti all’ingrosso per offrire il vero sapore della cucina italiana." />
    <link rel="canonical" href="https://alysei.com/it/services/italian-restaurants" />

    {/* <!-- Open Graph / Facebook --> */}
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Fornitori per Ristoranti Italiani | Alysei" />
    <meta property="og:description" content="Scopri i migliori fornitori per ristoranti italiani su Alysei. Trova cibo autentico, vino e prodotti all’ingrosso per offrire il vero sapore della cucina italiana." />
    <meta property="og:url" content="https://alysei.com/it/services/italian-restaurants" />
    <meta property="og:site_name" content="Alysei" />
    <meta property="og:image" content="https://alysei.com/dist/images/restaurent-contentimg.png" />

    {/* <!-- Twitter --> */}
    <meta property="twitter:card" content="summary" />
    <meta property="twitter:url" content="https://alysei.com/it/services/italian-restaurants" />
    <meta property="twitter:title" content="Fornitori per Ristoranti Italiani | Alysei" />
    <meta property="twitter:description" content="Scopri i migliori fornitori per ristoranti italiani su Alysei. Trova cibo autentico, vino e prodotti all’ingrosso per offrire il vero sapore della cucina italiana." />
    <meta property="twitter:image" content="https://alysei.com/dist/images/restaurent-contentimg.png" />

  </Helmet>
}