import React from "react";
// import { Helmet } from "react-helmet"
import { Helmet } from "react-helmet-async";
import useLocale from "../../Hooks/useLocale";
const ItalianFood = () => {
    const localeLocalization = useLocale()
    return localeLocalization == 'it' ? <ItalianCom /> : <EnglishCom />

}

export default ItalianFood;
const EnglishCom = () => {
    return<Helmet prioritizeSeoTags>
        <meta charSet="UTF-8" />
        <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
        <link rel="alternate" href="https://alysei.com/en/services/italian-food-beverage-producers" hreflang="en" />
        <link rel="alternate" href="https://alysei.com/it/services/italian-food-beverage-producers" hreflang="it" />

        {/* <!-- Primary Meta Tags --> */}
        <title>Italian Food & Beverage Producers USA | Alysei</title>
        <meta name="description" content="Explore top Italian food and beverage producers in the USA with Alysei. Source high-quality, authentic Italian products directly from manufacturers for your business, including artisan foods and wines." />
        <meta name="keywords" content="Italian beverage manufacturers USA, Authentic Italian food producers USA, Italian food manufacturers USA, Italian wine producers USA, Italian artisan food producers USA, Wholesale Italian food producers USA, Italian food and beverage suppliers USA, Italian culinary product manufacturers USA, Authentic Italian food sourcing USA" />
        <link rel="canonical" href="https://alysei.com/en/services/italian-food-beverage-producers" />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Italian Food & Beverage Producers USA | Alysei" />
        <meta property="og:description" content="Explore top Italian food and beverage producers in the USA with Alysei. Source high-quality, authentic Italian products directly from manufacturers for your business, including artisan foods and wines." />
        <meta property="og:url" content="https://alysei.com/en/services/italian-food-beverage-producers" />
        <meta property="og:site_name" content="Alysei" />
        <meta property="og:image" content="https://alysei.com/dist/images/producer-thumbnail.png" />

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:url" content="https://alysei.com/en/services/italian-food-beverage-producers" />
        <meta property="twitter:title" content="Italian Food & Beverage Producers USA | Alysei" />
        <meta property="twitter:description" content="Explore top Italian food and beverage producers in the USA with Alysei. Source high-quality, authentic Italian products directly from manufacturers for your business, including artisan foods and wines." />
        <meta property="twitter:image" content="https://alysei.com/dist/images/producer-thumbnail.png" />

    </Helmet>
}
const ItalianCom = () => {
    return <Helmet prioritizeSeoTags>
        <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
        <link rel="alternate" href="https://alysei.com/en/services/italian-food-beverage-producers" hreflang="en" />
        <link rel="alternate" href="https://alysei.com/it/services/italian-food-beverage-producers" hreflang="it" />

        <title>Produttori Italiani di Cibo e Bevande Autentici | Alysei</title>
        <meta name="description" content="Connetti con produttori italiani di cibo e bevande autentici su Alysei. Scopri fornitori affidabili di prodotti enogastronomici di alta qualità per il mercato all’ingrosso." />
        <link rel="canonical" href="https://alysei.com/it/services/italian-food-beverage-producers" />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Produttori Italiani di Cibo e Bevande Autentici | Alysei" />
        <meta property="og:description" content="Connetti con produttori italiani di cibo e bevande autentici su Alysei. Scopri fornitori affidabili di prodotti enogastronomici di alta qualità per il mercato all’ingrosso." />
        <meta property="og:url" content="https://alysei.com/it/services/italian-food-beverage-producers" />
        <meta property="og:site_name" content="Alysei" />
        <meta property="og:image" content="https://alysei.com/dist/images/producer-thumbnail.png" />

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:url" content="https://alysei.com/it/services/italian-food-beverage-producers" />
        <meta property="twitter:title" content="Produttori Italiani di Cibo e Bevande Autentici | Alysei" />
        <meta property="twitter:description" content="Connetti con produttori italiani di cibo e bevande autentici su Alysei. Scopri fornitori affidabili di prodotti enogastronomici di alta qualità per il mercato all’ingrosso." />
        <meta property="twitter:image" content="https://alysei.com/dist/images/producer-thumbnail.png" />

    </Helmet>
}