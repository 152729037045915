import React from 'react'
// import { Helmet } from 'react-helmet'
import { Helmet } from 'react-helmet-async';
import useLocale from '../Hooks/useLocale';

const SeoSignup = () => {
  const localeLocalization = useLocale()
  return localeLocalization == 'it' ? <ItalianCom /> : <EnglishCom />
}

export default SeoSignup

const EnglishCom = () => {
  return <Helmet prioritizeSeoTags>
    <meta charSet="UTF-8" />
    <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
    <link rel="alternate" href="https://alysei.com/en/signup" hreflang="en" />
    <link rel="alternate" href="https://alysei.com/it/signup" hreflang="it" />

    {/* <!-- Primary Meta Tags --> */}
    <title>Sign Up for Alysei - Join the Best Italian Food & Beverage Platform USA</title>
    <meta name="description" content="Sign up for Alysei today and join the leading platform connecting Italian food and beverage enthusiasts, businesses, and professionals in the USA. Start your journey now!" />
    <meta name="keywords" content="Alysei Sign Up, Register on Alysei, Create Alysei Account" />
    <link rel="canonical" href="https://alysei.com/en/signup" />

    {/* <!-- Open Graph / Facebook --> */}
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Sign Up for Alysei - Join the Best Italian Food & Beverage Platform USA" />
    <meta property="og:description" content="Sign up for Alysei today and join the leading platform connecting Italian food and beverage enthusiasts, businesses, and professionals in the USA. Start your journey now!" />
    <meta property="og:url" content="https://alysei.com/en/signup" />
    <meta property="og:site_name" content="Alysei" />
    <meta property="og:image" content="https://alysei.com/dist/images/login-to-alysei.png" />

    {/* <!-- Twitter -s-> */}
    <meta property="twitter:card" content="summary" />
    <meta property="twitter:url" content="https://alysei.com/en/signup" />
    <meta property="twitter:title" content="Sign Up for Alysei - Join the Best Italian Food & Beverage Platform USA" />
    <meta property="twitter:description" content="Sign up for Alysei today and join the leading platform connecting Italian food and beverage enthusiasts, businesses, and professionals in the USA. Start your journey now!" />
    <meta property="twitter:image" content="https://alysei.com/dist/images/login-to-alysei.png" />

  </Helmet>
}
const ItalianCom = () => {
  return <Helmet prioritizeSeoTags>
    <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
    <link rel="alternate" href="https://alysei.com/en/signup" hreflang="en" />
    <link rel="alternate" href="https://alysei.com/it/signup" hreflang="it" />

    {/* <!-- Primary Meta Tags --> */}
    <title>Registrati su Alysei | Unisciti alla Migliore Community Food & Beverage</title>
    <meta name="description" content="Iscriviti ad Alysei e scopri il network dedicato agli appassionati, ai professionisti e alle aziende del settore food & beverage italiano. Inizia oggi!" />
    <link rel="canonical" href="https://alysei.com/it/signup" />

    {/* <!-- Open Graph / Facebook --> */}
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Registrati su Alysei | Unisciti alla Migliore Community Food & Beverage" />
    <meta property="og:description" content="Iscriviti ad Alysei e scopri il network dedicato agli appassionati, ai professionisti e alle aziende del settore food & beverage italiano. Inizia oggi!" />
    <meta property="og:url" content="https://alysei.com/it/signup" />
    <meta property="og:site_name" content="Alysei" />
    <meta property="og:image" content="https://alysei.com/dist/images/login-to-alysei.png" />

    {/* <!-- Twitter --> */}
    <meta property="twitter:card" content="summary" />
    <meta property="twitter:url" content="https://alysei.com/it/signup" />
    <meta property="twitter:title" content="Registrati su Alysei | Unisciti alla Migliore Community Food & Beverage" />
    <meta property="twitter:description" content="Iscriviti ad Alysei e scopri il network dedicato agli appassionati, ai professionisti e alle aziende del settore food & beverage italiano. Inizia oggi!" />
    <meta property="twitter:image" content="https://alysei.com/dist/images/login-to-alysei.png" />


  </Helmet>
}